import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-kamine',
  templateUrl: './kamine.component.html',
  styleUrls: ['./kamine.component.scss']
})
export class KamineComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
  ) { }

  numberfiles;
  kamindata;
  kamindatalive;
  docID;

  detailpage;
  detailpagemodal: boolean = false;

  ngOnInit() {
    this.getKamindata();
    this.getKamindataLivesub();
  }


  getKamindata() {
    this.afs.collection('files', ref => {
     return ref.where('kamine', '==', true);
   }).get()
    .subscribe(snaps => {
      this.kamindata = (snaps.docs.map(snap => snap.data()))
      console.log(this.kamindata);
      this.numberfiles = this.kamindata.length;
    });
  }

  getKamindataLivesub = () =>
  this.getKamindataLive().subscribe(res => (this.kamindatalive = res))

  getKamindataLive() {
    return this.afs.collection('files', ref => {
      return ref.where('kamine', '==', true);
      }).snapshotChanges();
  }

  OpenDetailpage(data) {
    this.detailpage = data.payload.doc.data();
    this.docID = this.docID = data.payload.doc.id;
    console.log(this.docID);
    console.log(this.detailpage);
    this.detailpagemodal = true;
  }

  setDone() {
    this.detailpagemodal = false;
    console.log(this.docID);
      return this.afs
          .collection('files')
          .doc(this.docID)
          .set({ kamine: false }, { merge: true});
  }

}
