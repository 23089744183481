<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>Neue Aufträge: <strong>{{size}} </strong></h1>
        <div class="clr-row">
            <div *ngFor="let order of files" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                <div class="card">
                    <div class="card-header">
                        <strong>{{order.payload.doc.data().companyname}}</strong>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{order.payload.doc.data().description}}
                        </div>
                        <hr>
                        <div class="card-text">
                            <strong>Patient:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().patname }}
                                {{ order.payload.doc.data().patlastname }}</span>
                            <br>
                            <strong>Techniker:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().technican }}</span>
                            <br>
                            <hr>
                            <strong>Einheiten: </strong>
                            <br>
                            <span>{{ order.payload.doc.data().ordernumber }}</span>
                            <br>
                            <strong>Auftragsdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                            <br>
                            <strong>Lieferdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().deliverydate }}</span>
                            <br>
                            <strong>Was wurde gesendet?</strong>
                            <br>
                            <span>{{ order.payload.doc.data().whattosend }}</span>
                            <br>
                            <hr>
                            <strong>Material: </strong>
                            <br>
                            <span>{{ order.payload.doc.data().materialtype }}</span>
                            <br>
                            <strong>Materialmarke: </strong>
                            <br>
                            <span>{{ order.payload.doc.data().materialbrand }}</span>
                            <br>
                            <strong>Zahnfarbe: </strong>
                            <br>
                            <span>{{ order.payload.doc.data().teethcolor }}</span>
                            <br>
                            <hr>
                            <strong>Dateiname:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().path }}</span>
                            <br>
                            <hr>
                            <clr-signpost>
                                <button class="btn btn-link" clrSignpostTrigger>
                                    Notitzen
                                    <clr-icon shape="details"></clr-icon>
                                </button>
                                <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                    {{ order.payload.doc.data().notes }}
                                </clr-signpost-content>
                            </clr-signpost>
                        </div>
                    </div>
                    <button [disabled]="activbutton" *ngIf="order.payload.doc.data().whattosend == 'Interoralscan'" (click)="setIOS(order)"
                    class="btn btn-primary btn-block">Interoralscan gespeichert</button>
                    <button [disabled]="activbutton" *ngIf="order.payload.doc.data().whattosend == 'loeffel'" (click)="setTray(order)"
                        class="btn btn-primary btn-block">Auftrag Löffel</button>
                    <button [disabled]="activbutton" (click)="Modalopen(order)" class="btn btn-primary btn-block">Auftrag Bestätigen</button>
                    <div *ngIf="order.payload.doc.data().docID == ModalID">
                        <clr-modal [(clrModalOpen)]="isOpen">
                            <h3 class="modal-title">Auftrag Bestätigen</h3>
                            <div class="modal-body">
                                <p>Möchten Sie den ausgewählten Auftrags bestätigen?</p>
                                <p><strong>"{{order.payload.doc.data().description}}"</strong></p>
                                <form clrForm [formGroup]="this.firebaseService.commentform">
                                    <clr-textarea-container>
                                        <label>Kommentare</label>
                                        <textarea clrTextarea formControlName="extracomment"
                                            name="Kommentare"></textarea>
                                    </clr-textarea-container>
                                </form>
                            </div>
                            <div class="modal-footer">
                                <button (click)="isOpen = !isOpen" type="button"
                                    class="btn btn-outline">Abbrechen</button>
                                <button type="button" class="btn btn-primary" (click)="Submit(order)">Ok</button>
                            </div>
                        </clr-modal>
                    </div>
                    <div class="card-footer">
                        <a (click)="activbuttonfuction()" href="{{order.payload.doc.data().downloadURL}}" class="btn btn-sm">
                            <clr-icon shape="download"></clr-icon>Download
                        </a>
                        <a (click)="Modalopen2(order)" class="btn btn-danger btn-sm">
                            <clr-icon shape="trash"></clr-icon>Löschen
                        </a>
                        <div *ngIf="order.payload.doc.data().docID == ModalID">
                            <clr-modal [(clrModalOpen)]="isOpen2">
                                <h3 class="modal-title">Auftrag Löschen</h3>
                                <div class="modal-body">
                                    <div class="alert alert-danger" role="alert">
                                        <div class="alert-items">
                                            <div class="alert-item static">
                                                <div class="alert-icon-wrapper">
                                                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                                                </div>
                                                <span class="alert-text">
                                                    Der Auftrag
                                                    <strong>"{{order.payload.doc.data().description}}"</strong> mit
                                                    Datei <strong>"{{ order.payload.doc.data().path }}"</strong> werden
                                                    unwiederruflich gelöscht!
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <p>Möchten Sie den ausgewählten Auftrags wirklich löschen?</p>
                                </div>
                                <div class="modal-footer">
                                    <button (click)="isOpen2 = !isOpen2" type="button"
                                        class="btn btn-outline">Abbrechen</button>
                                    <button type="button" class="btn btn-danger"
                                        (click)="SubmitDelete(order)">Löschen</button>
                                </div>
                            </clr-modal>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>