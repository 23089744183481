import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common'
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})
export class OverviewComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    public datepipe: DatePipe,
    private excelService: ExcelService
  ) { }

  files;
  today;
  date: Date;
  datePlus1;
  latest_date;
  negativdate2;
  test;
  exportfiles;
  firstdate: string = "22.10.2019";
  seconddate: string = "22.10.2019";
  unixfirstdate;
  unixseconddate;
  export: boolean = false;
  companyname;
  description;
  testarray;
  numberfiles;

  deliverydate;
  docid;



  ngOnInit() {
    this.setdate();
  }

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.exportfiles, 'Uebersicht');
 }

 convertUnixDates () {
   console.log(this.firstdate)
   console.log(this.seconddate)
   const firstdatesplit = this.firstdate.split('.');
   const newfirstdate = (firstdatesplit[2] + '/' + firstdatesplit[1] + '/' + firstdatesplit[0])
   const seconddatesplit = this.seconddate.split('.');
   const newseconddate = (seconddatesplit[2] + '/' + seconddatesplit[1] + '/' + seconddatesplit[0])
   const unixfirstdate = Math.round(new Date(newfirstdate).getTime()/1000)
   const unixseconddate = Math.round(new Date(newseconddate).getTime()/1000)
   this.unixfirstdate = unixfirstdate + '000';
   this.unixseconddate = unixseconddate + '000';
   console.log(this.unixfirstdate);
   console.log(this.unixseconddate);
   console.log(Date.now());
   this.getExportdata();
   this.export = true;
 }

 getExportdata() {
   this.afs.collection('files', ref => {
    return ref.where('date', '>=', this.unixfirstdate).where('date', '<=', this.unixseconddate);
  }).get()
   .subscribe(snaps => {
     this.exportfiles = (snaps.docs.map(snap => snap.data()))
     this.companyname = (snaps.docs.map(snap => snap.data().companyname))
     this.description = (snaps.docs.map(snap => snap.data().description))
     console.log(this.exportfiles);
     this.numberfiles = this.exportfiles.length;
   });
 }

  setdate() {
    this.date = new Date();
    this.latest_date = this.datepipe.transform(this.date, 'dd.MM.yyyy');
    console.log(this.latest_date);
  }
  getUploads = () =>
  this.firebaseService.getallUploads().subscribe(res => (this.files = res))

  setnegativdatetest(data) {
    console.log(data.payload.doc.data().deliverydate)
  }

  setnegativdate() {
    const dmy = this.deliverydate;   // your input string
    const a = dmy.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 -(+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = ( y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  Submit(data) {
    this.setdatenegativ(data);
    }

  setdatenegativ = data =>
  this.firebaseService.setnegativdatefinal(data)

  setnewdate(data) {
    this.docid = data.docID;
    console.log(this.docid);
    this.deliverydate = data.deliverydate;
    console.log(this.deliverydate)
    this.setnegativdate();
    this.setnegativdatefinal();
  }


  setnegativdatefinal() {
    return this.afs
      .collection('files')
      .doc(this.docid)
      .set({
        negativdate: this.negativdate2
      }, {
        merge: true
      });

  }

  pullback(data) {
    return this.afs
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 10,
        millingfinish: true
      }, {
        merge: true
      });

  }
}
