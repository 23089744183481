import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
  ) { }

  size: number = 0;
  worknumber: number = 0;
  iosnumber: number = 0;
  worknumberUnits: number = 0;
  billingnumber: number = 0;
  preparenumber: number = 0;
  preparenumber2: number = 0;
  preparetotal: number = 0;
  printnumber: number = 0;
  totalnumber: number = 0;
  shippingnumber: number = 0;
  shippingnumber2: number = 0;
  shippingnumbertotal: number = 0;
  kaminnumber: number = 0;
  millingnumber: number = 0;
  millingUnitnumber: number = 0;

  zirkonnumber: number = 0;
  zirkonnumber2: number = 0;
  zirkonnumbertotal: number = 0;

  Number2files;
  basic = false;

  ngOnInit() {
    this.getDocNumber();
    this.getIosNumber();
    this.getDocNumber2Units();
    this.getDocNumber3();
    this.getDocNumber5();
    this.getPrepareMillingCount();
    this.getPrepareMillingCount2();
    this.getPrepareMillingCountTotal();
    this.getShippingCount();
    this.getShippingCount2();
    this.getkaminCount();
    this.getZirkonCount();
    this.getZirkonCount2();
    this.getMachineUploadsNumbers();
   }

  getAllNumbers() {
    this.getDocNumber();
    this.getIosNumber();
    this.getDocNumber2Units();
    this.getDocNumber3();
    this.getDocNumber5();
    this.getPrepareMillingCount();
    this.getPrepareMillingCount2();
    this.getPrepareMillingCountTotal();
    this.getShippingCount();
    this.getShippingCount2();
    this.getkaminCount();
    this.getZirkonCount();
    this.getZirkonCount2();
    this.getMachineUploadsNumbers();
  }

  getDocNumber = () =>
  this.firebaseService.NewOrderCount().subscribe(snap => {
    this.size = snap.size;
  })

  getIosNumber = () =>
    this.firebaseService.NewIosCount().subscribe(snap => {
      this.iosnumber = snap.size;
    })

  getkaminCount = () =>
  this.firebaseService.KaminCount().subscribe(snap => {
    this.kaminnumber = snap.size;
  })


  getDocNumber2Units() {
    this.afs.collection('files', ref => {
     return ref.where('status', '==', 2);
   }).get()
    .subscribe(snaps => {
      this.Number2files = (snaps.docs.map(snap => snap.data()))
      const ordernumber = (snaps.docs.map(snap => snap.data().ordernumber))
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.worknumberUnits = ordernumber.reduce(reducer);
      this.worknumber = this.Number2files.length;
    });
  }

  getMachineUploadsNumbers() {
    this.afs.collection('files', ref => {
     return ref.where('blankmillingfinish', '==', false).where('workingmachineavaible', '==', 0);
   }).get()

    .subscribe(snaps => {
      const FilesMachine = (snaps.docs.map(snap => snap.data()))
      const ordernumber = (snaps.docs.map(snap => snap.data().ordernumber))
      const reducer = (accumulator, currentValue) => accumulator + currentValue;
      this.millingUnitnumber = ordernumber.reduce(reducer);
      this.millingnumber = FilesMachine.length;
    });
  }

  getDocNumber3 = () =>
  this.firebaseService.BillingCount().subscribe(snap => {
    this.billingnumber = snap.size;
  })

  getPrepareMillingCount = () =>
  this.firebaseService.PrepareMillingCount().subscribe(snap => {
    this.preparenumber = snap.size;
  })

  getPrepareMillingCount2 = () =>
  this.firebaseService.PrepareMillingCount2().subscribe(snap => {
    this.preparenumber2 = snap.size;
    this.getPrepareMillingCountTotal();
  })

  getPrepareMillingCountTotal() {
    this.preparetotal = this.preparenumber + this.preparenumber2;
  }

  getShippingCount = () =>
  this.firebaseService.ShippingCount().subscribe(snap => {
    this.shippingnumber = snap.size;
  })

  getShippingCount2 = () =>
  this.firebaseService.ShippingCount2().subscribe(snap => {
    this.shippingnumber2 = snap.size;
    this.getShippingCountTotal();
  })

  getShippingCountTotal() {
    this.shippingnumbertotal = this.shippingnumber + this.shippingnumber2;
  }


  getZirkonCount = () =>
  this.firebaseService.ZirkonCount().subscribe(snap => {
    this.zirkonnumber = snap.size;
  })

  getZirkonCount2 = () =>
  this.firebaseService.ZirkonCount2().subscribe(snap => {
    this.zirkonnumber2 = snap.size;
    this.getZirkonCountTotal();
  })

  getZirkonCountTotal() {
    this.zirkonnumbertotal = this.zirkonnumber + this.zirkonnumber2;
  }

  getDocNumber5 = () =>
  this.firebaseService.PrintingCount().subscribe(snap => {
    this.printnumber = snap.size;
  })
}

