import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { getNumberOfCurrencyDigits } from '@angular/common';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-upload-list',
  templateUrl: './upload-list.component.html',
  styleUrls: ['./upload-list.component.scss'],

})
export class UploadListComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    private firebaseService: FirebaseService) { }

  isOpen = false;
  isOpen2 = false;
  files;
  size;
  ModalID;
  activbutton: boolean = true;

  ngOnInit() {
    this.getUploads();
    this.getTotalNumber();
    this.firebaseService.NewOrderCount();
  }

  activbuttonfuction() {
    this.activbutton = false;
  }

  setTray(data) {
      return this.afs
          .collection('files')
          .doc(data.payload.doc.id)
          .set({ status: 0, companyname: 'Dental-Technik Hamm'}, { merge: true});
  }

  setIOS(data) {
    const docID = data.payload.doc.id
    const docID2 = data.payload.doc.id
    return this.afs
        .collection('files')
        .doc(data.payload.doc.id)
        .set({ status: 30, docID, docID2}, { merge: true});
}


  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.getUploads().subscribe(res => (this.files = res))

  getTotalNumber = () =>
  this.firebaseService.NewOrderCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  Submit(data) {
    this.markCompleted(data);
    this.isOpen = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }

  Modalopen (data) {
    this.SetdocIDandUpdate(data);
    this.MatchDocID(data);
    this.isOpen = true;
  }

  Modalopen2 (data) {
    this.SetdocIDandUpdate(data);
    this.MatchDocID(data);
    this.isOpen2 = true;
  }

  SetdocIDandUpdate = data =>
  this.firebaseService.setdocID(data)

  markCompleted = data =>
    this.firebaseService.updateOrder(data)

    MatchDocID(data) {
      this.ModalID = data.payload.doc.id;
    }

}




