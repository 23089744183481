import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';



interface Rohlinge {
  blankmaterial: any;
  blankstatus: boolean;
  blankName: any;
  cargeNumber: number;
}


@Component({
  selector: 'app-blank-list',
  templateUrl: './blank-list.component.html',
  styleUrls: ['./blank-list.component.scss']
})
export class BlankListComponent implements OnInit {

  notesCollection: AngularFirestoreCollection<Rohlinge>;
  titan: Observable<Rohlinge[]>;

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore
    ) { }

  rohlinge;

  titanrohlinge;

  dentalmaterial;

  specialrohling;

  allopen = false;
  materialopen = false;

  ngOnInit() {
    this.getDentalmaterial();
  }

  deleteiconShow(data): boolean {
    const material: string = data.payload.doc.data().material;
    switch (material) {
      case 'PMMA opak':
      case 'PMMA rosa':
        return true;
    }
    return false;
  }



  Onsubmit(data) {
    this.setBlankStatus(data);
    }

  setBlankStatus = data => this.firebaseService.setBlankStatus(data);

  DeleteBlank = data => this.firebaseService.deleteRohlinge(data);

  getRohlinge() {
    this.allopen = true;
    this.materialopen = false;
     this.firebaseService
     .getRohlinge()
     .subscribe(res => (this.rohlinge = res));
    }

    getallactivRohlinge() {
      this.allopen = true;
      this.materialopen = false;
       this.firebaseService
       .getallactivRohlinge()
       .subscribe(res => (this.rohlinge = res));
      }

      getallinactivRohlinge() {
        this.allopen = true;
        this.materialopen = false;
         this.firebaseService
         .getallinactivRohlinge()
         .subscribe(res => (this.rohlinge = res));
        }


    getDentalmaterial() {
      this.firebaseService
      .getDentalmaterial()
      .subscribe(res => (this.dentalmaterial = res));
     }

     getMaterialtypeRohling(data) {
      this.allopen = false;
      this.materialopen = true;
      this.firebaseService
      .getMaterialtypeRohling(data)
      .subscribe(res => (this.specialrohling = res));
     }
     
}



