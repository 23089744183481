<clr-datagrid>
        <clr-dg-column>Bezeichnung</clr-dg-column>
        <clr-dg-column>Abkürzung</clr-dg-column>
        <clr-dg-column></clr-dg-column>
    
        <clr-dg-row *ngFor="let order of dentalmaterial">
          <clr-dg-cell>{{order.payload.doc.data().werkstoffName}}</clr-dg-cell>
          <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().shortName}}</span>
        </clr-dg-cell>
          <clr-dg-cell><clr-icon (click)="deletematerial(order)" shape="trash" size="24"></clr-icon></clr-dg-cell>
        </clr-dg-row>
</clr-datagrid>