
import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

  isOpen = false;
  isOpen2 = false;
  isOpen3 = false;
  showInfo = true;
  showadress = false;
  files;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohlingcharge;
  ModalID;
  companyadress;

  public options: string;

  ngOnInit() {
    this.getUploads();
    this.getTotalNumber();
    this.getDentalrohling();
    this.firebaseService.NewOrderCount();
  }

  sendBillingMail(data) {
    console.log('Email ist gesendet');
    const beschreibung = data.payload.doc.data().description
    const werkstoff =  data.payload.doc.data().materialbrand
    const company = data.payload.doc.data().companyname
    this.afs.collection('mail').add(
      {
        to: 'a.hamm@zt-sauerland.de',
        cc: 'v.hamm-zentrale@t-online.de', 
        bcc: 'dwinkelmann@outlook.com', 
        template: {
         name: 'BillMail',
         data: {
          company: company,
          werkstoff: werkstoff,
          beschreibung: beschreibung,
         }
        }
       })}

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.getbillingUploads().subscribe(res => (this.files = res))

  getTotalNumber = () =>
  this.firebaseService.BillingCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  getDentalmaterial() {
    this.firebaseService
    .getDentalmaterial()
    .subscribe(res => (this.dentalmaterial = res));
   }

   getDentalrohling() {
    this.firebaseService
    .getRohlinge()
    .subscribe(res => (this.dentalrohling = res));
   }

   getDentalrohlingcharge() {
    this.firebaseService
    .getChargenumber()
    .subscribe(res => (this.dentalrohlingcharge = res));
   }

   getCompanyadress(data) {
     console.log(data.payload.doc.data().UserID);
    this.firebaseService
    .getAdress(data)
    .subscribe(res => (this.companyadress = res));
   }


  Submit(data) {
    this.markCompleted(data);
    this.isOpen = false;
    this.isOpen3 = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }


  markCompleted = data =>
    this.firebaseService.updatebillingOrder(data)

    moveBack = data =>
    this.firebaseService.movetoNew(data)

    toggleDisplay() {
      this.showInfo = !this.showInfo;
    }

    toggleDisplay2() {
      this.isOpen = !this.isOpen;
      this.isOpen3 = !this.isOpen3;
    }
    getOptions() {
      this.getDentalrohlingcharge();
    }

    copyMessage(val: string) {
      const selBox = document.createElement('textarea');
      selBox.style.position = 'fixed';
      selBox.style.left = '0';
      selBox.style.top = '0';
      selBox.style.opacity = '0';
      selBox.value = val;
      document.body.appendChild(selBox);
      selBox.focus();
      selBox.select();
      document.execCommand('copy');
      document.body.removeChild(selBox);
    }

    MatchDocID(data) {
      this.ModalID = data.payload.doc.id;
      this.SetDocID(data);
    }
    DisplayModal1(data) {
      this.MatchDocID(data);
      console.log('Modal1ID:  ' + this.ModalID);
      this.isOpen = true;
    }
    DisplayModal2(data) {
      this.MatchDocID(data);
      this.isOpen3 = true;
    }

    DisplayAdressModal(data) {
      this.MatchDocID(data);
      this.getCompanyadress(data);
      this.showadress = true;
    }

    SetDocID(data) {
      const docID: string = data.payload.doc.id;
      const docID2: string = data.payload.doc.id;
      return this.afs
        .collection('files')
        .doc(data.payload.doc.id)
        .set({
          docID, docID2
        }, {
          merge: true
        });
    }

}

