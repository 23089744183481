<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>Fertig für den Versand: <strong>{{size}} </strong></h1>
        <a (click)="toggleDisplay()" class="btn btn-sm">
            <clr-icon shape="download"></clr-icon>Mehr Infos
        </a>
        <a *ngIf="!multiselect" (click)="enablemultiselect()" class="btn btn-sm">
            <clr-icon shape="download"></clr-icon>Mehrfachauswahl
        </a>
        <a *ngIf="multiselect" (click)="enablemultiselect()" class="btn btn-sm">
            <clr-icon shape="download"></clr-icon>Mehrfachauswahl beenden
        </a>
        <a *ngIf="multiselect" (click)="multidelete()" class="btn btn-sm">
            <clr-icon shape="trash"></clr-icon>Alles löschen
        </a>
        <div class="clr-row">
            <div *ngFor="let order of files" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div>


                    <div [ngStyle]="{'background-color': (selecteditems.includes(order.payload.doc.id)) ? '#add8e6' : ''}"
                        class="card">

                        <div class="card-header">
                            <a *ngIf="selecteditems.includes(order.payload.doc.id)"
                                (click)="removeselectedelement(order.payload.doc.id)" class="btn btn-sm">
                                <clr-icon shape="trash"></clr-icon>Abwählen
                            </a>
                            <a *ngIf="multiselect && !selecteditems.includes(order.payload.doc.id)"
                                (click)="addselectelement(order.payload.doc.id)" class="btn btn-sm">
                                <clr-icon shape="download"></clr-icon>Auswählen
                            </a>
                            <strong>{{order.payload.doc.data().companyname}} </strong>
                            <clr-icon (click)="OpenDetailpage(order)" shape="cog"></clr-icon>
                        </div>
                        <div class="card-block">
                            <div class="card-title">
                                {{order.payload.doc.data().description}} <span
                                    class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                                <div style="height: 5px"
                                    [ngStyle]="{'background-color':getColor(order.payload.doc.data().materialbrand)}">
                                </div>
                            </div>
                            <hr>
                            <div class="card-text">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>Rohling
                                                <br>
                                                <strong>{{ order.payload.doc.data().workingblank }}</strong>
                                            </td>
                                            <td>Maschine
                                                <br>
                                                <strong>{{ order.payload.doc.data().workingmachine }}</strong>
                                        </tr>
                                        <tr>
                                            <td>Werkstoff
                                                <br>
                                                <strong>{{ order.payload.doc.data().teethcolor }}
                                                    {{ order.payload.doc.data().materialbrand }}</strong>
                                            <td>Lieferdatum
                                                <br>
                                                <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                                        </tr>
                                        <tr>
                                            <td>Patientename
                                                <br>
                                                <strong>{{ order.payload.doc.data().patlastname }},
                                                    {{ order.payload.doc.data().patname }}</strong>
                                            <td>Techniker
                                                <br>
                                                <strong>{{ order.payload.doc.data().technican }}</strong>
                                        </tr>
                                    </tbody>
                                </table>
                                <div [hidden]="showInfo">
                                    <strong>Auftragsdatum:</strong>
                                    <br>
                                    <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                                    <br>
                                    <strong>Was wurde gesendet?</strong>
                                    <br>
                                    <span>{{ order.payload.doc.data().whattosend }}</span>
                                    <br>
                                    <hr>
                                    <strong>Modelle drucken?</strong>
                                    <br>
                                    <span>{{ order.payload.doc.data().modelprinting }}</span>
                                    <br>
                                    <hr>
                                    <strong>Dateiname:</strong>
                                    <br>
                                    <span>{{ order.payload.doc.data().path }}</span>
                                    <br>
                                    <hr>
                                    <clr-signpost>
                                        <button class="btn btn-link" clrSignpostTrigger>
                                            Notitzen
                                            <clr-icon shape="details"></clr-icon>
                                        </button>
                                        <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                            {{ order.payload.doc.data().notes }}
                                        </clr-signpost-content>
                                    </clr-signpost>
                                </div>
                            </div>
                        </div>
                        <button (click)="OpenConfirmPagefirst(order)" class="btn btn-primary btn-block">Auftrag
                            abschließen</button>
                    </div>
                </div>
            </div>
            <div *ngFor="let order of files2" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div class="card">
                    <div class="card-header">
                        <strong>{{order.payload.doc.data().companyname}} <span class="label label-purple">Teil
                                2</span></strong>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{order.payload.doc.data().description}} <span
                                class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                            <div style="height: 5px"
                                [ngStyle]="{'background-color':getColor(order.payload.doc.data().materialbrand)}">
                            </div>
                        </div>
                        <hr>
                        <div class="card-text">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Rohling
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingblank2 }}</strong>
                                        </td>
                                        <td>Maschine
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingmachine2 }}</strong>
                                    </tr>
                                    <tr>
                                        <td>Werkstoff
                                            <br>
                                            <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                                        <td>Farbe
                                            <br>
                                            <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                                    </tr>
                                    <tr>
                                        <td>Zeit
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingtime2 }}</strong>
                                        <td>Lieferdatum
                                            <br>
                                            <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                                    </tr>
                                </tbody>
                            </table>
                            <div [hidden]="showInfo">
                                <strong>Auftragsdatum:</strong>
                                <br>
                                <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                                <br>
                                <strong>Was wurde gesendet?</strong>
                                <br>
                                <span>{{ order.payload.doc.data().whattosend }}</span>
                                <br>
                                <hr>
                                <strong>Modelle drucken?</strong>
                                <br>
                                <span>{{ order.payload.doc.data().printing }}</span>
                                <br>
                                <hr>
                                <strong>Dateiname:</strong>
                                <br>
                                <span>{{ order.payload.doc.data().path }}</span>
                                <br>
                                <hr>
                                <clr-signpost>
                                    <button class="btn btn-link" clrSignpostTrigger>
                                        Notitzen
                                        <clr-icon shape="details"></clr-icon>
                                    </button>
                                    <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                        {{ order.payload.doc.data().notes }}
                                    </clr-signpost-content>
                                </clr-signpost>
                            </div>
                        </div>
                    </div>

                    <button (click)="OpenConfirmPagesecond(order)" class="btn btn-primary btn-block">Auftrag
                        abschließen</button>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="detailpage">
        <clr-modal [(clrModalOpen)]="detailpagemodal">
            <h3 class="modal-title">Rechnungen</h3>
            <div class="modal-body">
                <form clrForm>
                    <h3>{{ detailpage.companyname }} - {{ detailpage.description }}</h3>
                    <clr-toggle-container clrInline>
                        <label>Rechnungen und Versand</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="workingbill" [(ngModel)]="workingbill" />
                            <label>Rechnung</label>
                        </clr-toggle-wrapper>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="workingshippingcost"
                                [(ngModel)]="workingshippingcost" />
                            <label>Versand</label>
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-textarea-container>
                        <label>Anmerkung</label>
                        <textarea clrTextarea [(ngModel)]="billnote" name="billnote"></textarea>
                    </clr-textarea-container>
                    <hr>
                    <clr-toggle-container clrInline>
                        <label>Kamine beschliffen</label>
                        <clr-toggle-wrapper>
                            <input type="checkbox" clrToggle name="kamine" value="kamine" [(ngModel)]="kamine" />
                        </clr-toggle-wrapper>
                    </clr-toggle-container>
                    <clr-select-container>
                        <label>Zuständiger Techniker</label>
                        <select clrSelect type="text" name="kamintechnican" [(ngModel)]="kamintechnican">
                            <option *ngFor="let data of technicans | async" value="{{ data.tecname }}">
                                <strong>{{ data.tecnumber }} - </strong>{{ data.tecname }} {{ data.teclastname }}
                            </option>
                        </select>
                    </clr-select-container>
                    <form clrForm>
                        <clr-input-container>
                            <label>Anzahl Einheiten für Kamine</label>
                            <input clrInput name="kaminnumber" type="number" [(ngModel)]="kaminnumber" />
                        </clr-input-container>
                    </form>
                </form>
            </div>
            <div class="modal-footer">
                <button (click)="detailpagemodal = !detailpagemodal" type="button"
                    class="btn btn-outline">Zurück</button>
                <button (click)="setnewSettings()" type="button" class="btn btn-primary">Ändern</button>
            </div>
        </clr-modal>
    </div>

    <div *ngIf="confirm">
        <clr-modal [(clrModalOpen)]="finishmodal">
            <h3 class="modal-title">Auftrag abschließen</h3>
            <div class="modal-body">
                <span>{{ confirm.companyname }} - {{ confirm.description }}</span>

            </div>
            <div class="modal-footer">
                <button (click)="finishmodal = !finishmodal" type="button" class="btn btn-outline">Zurück</button>
                <button (click)="Submit1()" type="button" class="btn btn-primary">Abschliessen :-)</button>
            </div>
        </clr-modal>
    </div>

    <div *ngIf="confirm2">
        <clr-modal [(clrModalOpen)]="finishmodal2">
            <h3 class="modal-title">Auftrag abschließen Teil 2</h3>
            <div class="modal-body">
                <span>{{ confirm2.companyname }} - {{ confirm2.description }}</span>

            </div>
            <div class="modal-footer">
                <button (click)="finishmodal2 = !finishmodal2" type="button" class="btn btn-outline">Zurück</button>
                <button (click)="Submit2()" type="button" class="btn btn-primary">Abschliessen :-)</button>
            </div>
        </clr-modal>
    </div>