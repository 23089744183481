<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>3D Druckaufträge:</h1>
        <div class="clr-row">
            <div *ngFor="let order of files" class=".clr-col-xl-2 clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div class="card">
                    <div class="card-header">

                            <div *ngIf="!order.payload.doc.data().notes == ''" class="alert alert-warning alert-sm"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="note"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        <span> {{ order.payload.doc.data().notes }}</span>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>

                        <div *ngIf="order.payload.doc.data().printstatus == 1" class="alert alert-app-level alert-info"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        Die Modelle wurden virtuell bearbeitet!
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="order.payload.doc.data().printstatus == 0" class="alert alert-success" role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="check-circle"></clr-icon>
                                    </div>
                                    <span class="alert-text">Neuer Auftrag</span>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="order.payload.doc.data().printstatus == 2" class="alert alert-app-level alert-info"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        Die 3D-Druckdateien wurden erstellt (Formlabs)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="order.payload.doc.data().printstatus == 3" class="alert alert-app-level alert-info"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        Die 3D-Druckdateien wurden erstellt (Anycubic)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="order.payload.doc.data().printstatus == 4" class="alert alert-app-level alert-info"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        Der 3D-Druck ist am drucken (Formlabs)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="order.payload.doc.data().printstatus == 5" class="alert alert-app-level alert-info"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        Der 3D-Druck ist am drucken (Anycubic)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <strong>{{order.payload.doc.data().companyname}}</strong>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{order.payload.doc.data().description}}
                        </div>
                        <hr>
                        <div class="card-text">
                          <strong>Techniker:</strong><br>
                          <span>{{ order.payload.doc.data().technican }}</span>
                          <br>
                            <strong>Patientenname:</strong><br>
                            <span>{{ order.payload.doc.data().patlastname }},
                                {{ order.payload.doc.data().patname }}</span>
                            <br>
                            <strong>Lieferdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().deliverydate }}</span>
                            <br>
                            <strong>Auftragsdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                            <br>
                        </div>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus == 0">
                        <button (click)="Submit(order)" class="btn btn-primary btn-block">Modelle erstellt</button>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus == 1">
                        <button (click)="Submit2(order)" class="btn btn-primary btn-block">Datei für
                            <strong>Formlabs</strong> vorbereitet</button>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus == 1">
                        <button (click)="Submit3(order)" class="btn btn-primary btn-block">Datei für
                            <strong>Anycubic</strong> vorbereitet</button>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus == 2">
                        <button (click)="Submit4(order)" class="btn btn-primary btn-block">Datei druckt auf dem
                            Formlabs</button>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus == 3">
                        <button (click)="Submit5(order)" class="btn btn-primary btn-block">Datei druckt auf dem
                            Anycubic</button>
                    </div>
                    <div *ngIf="order.payload.doc.data().printstatus > 3">
                        <button (click)="Submit6(order)" class="btn btn-primary btn-block">Fertigstellen</button>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
