<table class="table">
  <thead>
  <tr>
    <th></th>
    <th>SOLL</th>
    <th>IST</th>
    <th>Differenz</th>
    <th>Nach Korrektur</th>
  </tr>
  </thead>
  <tbody>
  <tr>
    <th>Durchmesser</th>
    <td>{{PrefaceISTDiameter}} mm</td>
    <td><input type="number" min="10" max="15" step="0.005" id="basic" placeholder="Durchmesser" (ngModelChange)="calculateValues()" [(ngModel)]="PrefaceInputDiameter" class="clr-input" />
    </td>
    <td>{{PrefaceDIFDiameter}} mm</td>
    <td>{{PrefaceKORDiameter}} mm</td>
  </tr>
  <tr>
    <th>X-Stop</th>
    <td>{{PrefaceISTXSTO}} mm</td>
    <td><input type="number" min="10" max="15" step="0.005" id="basic" placeholder="X+" [(ngModel)]="PrefaceInputXSTO" class="clr-input" />
    </td>
    <td>{{PrefaceDIFXSTO}} mm</td>
    <td>{{PrefaceKORXSTO}} mm</td>
  </tr>
  <tr>
    <th>Z+</th>
    <td>{{PrefaceISTZPOSITIV}} mm</td>
    <td><input type="number" min="9" max="11" step="0.005" id="basic" placeholder="Z+" [(ngModel)]="PrefaceInputZPOSITIV" class="clr-input" />
    </td>
    <td>{{PrefaceDIFZPOSITIV}} mm</td>
    <td>{{PrefaceKORZPOSITIV}} mm</td>
  </tr>
  <tr>
    <th>Z-</th>
    <td>{{PrefaceISTZNEGATIV}} mm</td>
    <td><input type="number" min="9" max="11" step="0.005" id="basic" placeholder="Z-" [(ngModel)]="PrefaceInputZNEGATIV" class="clr-input" /></td>
    <td>{{PrefaceDIFZNEGATIV}} mm</td>
    <td>{{PrefaceKORZNEGATIV}} mm</td>
  </tr>
  <tr>
    <th>Y+</th>
    <td>{{PrefaceISTYPOSITIV}} mm</td>
    <td><input type="number" min="9" max="11" step="0.005" id="basic" placeholder="Y+" [(ngModel)]="PrefaceInputYPOSITIV" class="clr-input" /></td>
    <td>{{PrefaceDIFYPOSITIV}} mm</td>
    <td>{{PrefaceKORYPOSITIV}} mm</td>
  </tr>
  <tr>
    <th>Y-</th>
    <td>{{PrefaceISTYNEGATIV}} mm</td>
    <td><input type="number" min="9" max="11" step="0.005" id="basic" placeholder="Y-" [(ngModel)]="PrefaceInputYNEGATIV" class="clr-input" /></td>
    <td>{{PrefaceDIFYNEGATIV}} mm</td>
    <td>{{PrefaceKORYNEGATIV}} mm</td>
  </tr>
  </tbody>
</table>
