<div class="clr-row">
  <div class="clr-col-1"></div>
  <div class="clr-col-11">
    <h1>
      Aktuelle Intraoral-3D-Scans: <strong>{{ checkNumber() }}</strong>
    </h1>
    <div>
      <div *ngIf="uncompletedUploads.length > 0" class="card">
        <div class="card-header">Unvollständige Aufträge</div>
        <div class="card-block">
          <ul class="list">
            <li *ngFor="let dentists of uncompletedUploads">
              <h4>{{ dentists[0].dentist }}</h4>
              <ul class="list-unstyled">
                <div *ngFor="let patient of dentists">
                  <li>
                    <span class="{{ getStatusColorClass(daysSinceCreated(patient.statusDate)) }}"
                      >Unvollständig seit<strong> &nbsp;{{ daysSinceCreated(patient.statusDate) }}&nbsp;</strong>Tagen</span
                    >&nbsp;{{ patient.patientName }}{{ patient.patientLastname }}&nbsp;
                  </li>
                  <div style="height: 5px"></div>
                </div>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div *ngIf="uncompletedUploadList.length > 0" class="card">
        <div class="card-header">Neue Uploads stehen zum Download bereit</div>
        <div class="card-block">
          <ul class="list">
            <li *ngFor="let upload of uncompletedUploadList">
              <ul style="padding-bottom: 1rem">
                <li style="list-style-type: none">
                  Zahnarzt: <strong>{{ upload.dentist }}</strong>
                </li>
                <li style="list-style-type: none">
                  Patient: <strong>{{ upload.uploads[0].patientLastName }} , {{ upload.uploads[0].patient }}</strong>
                </li>
                <li style="list-style-type: none">Dateiname: {{ upload.uploads[0].fileName }}</li>
                <li style="list-style-type: none">Upload-Datum: {{ upload.uploads[0].date | date }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <clr-tabs>
      <clr-tab>
        <button clrTabLink id="link1">Aktuelle Aufträge</button>
        <clr-tab-content id="content1" *clrIfActive="true">
          <app-upload-table [uploads]="userUploadsFirstTwoWeeks">> </app-upload-table>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Älter als 2 Wochen</button>
        <clr-tab-content *clrIfActive="false">
          <app-upload-table [uploads]="userUploadsTwoWeeks">> </app-upload-table>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Älter als 4 Wochen</button>
        <clr-tab-content *clrIfActive="false">
          <app-upload-table [uploads]="userUploadsFourWeeks">> </app-upload-table>
        </clr-tab-content>
      </clr-tab>
      <clr-tab>
        <button clrTabLink>Archiv</button>
        <clr-tab-content *clrIfActive="false">
          <app-upload-table [uploads]="userUploadsArchive">> </app-upload-table>
        </clr-tab-content>
      </clr-tab>
    </clr-tabs>
  </div>
</div>
