import { Component, OnInit,OnChanges  } from '@angular/core';

interface onChanges {
}

@Component({
  selector: 'app-prefacecal',
  templateUrl: './prefacecal.component.html',
  styleUrls: ['./prefacecal.component.scss']
})
export class PrefacecalComponent implements OnChanges {



  constructor() { }

  ngOnChanges(): void {

  }

  calculateValues() {
    this.PrefaceDIFDiameter = (this.PrefaceISTDiameter - this.PrefaceInputDiameter);
    this.PrefaceDIFXSTO = (this.PrefaceISTXSTO - this.PrefaceInputXSTO);
  }

  PrefaceISTDiameter = 11.5;
  PrefaceISTXSTO = 17.25;
  PrefaceISTZPOSITIV = 10;
  PrefaceISTZNEGATIV = 10;
  PrefaceISTYPOSITIV = 10;
  PrefaceISTYNEGATIV = 10;

  PrefaceInputDiameter = 11.5;
  PrefaceInputXSTO = 17.25;
  PrefaceInputZPOSITIV = 10;
  PrefaceInputZNEGATIV = 10;
  PrefaceInputYPOSITIV = 10;
  PrefaceInputYNEGATIV = 10;

  PrefaceDIFDiameter = this.PrefaceISTDiameter - this.PrefaceInputDiameter;
  PrefaceDIFXSTO = this.PrefaceISTXSTO - this.PrefaceInputXSTO;
  PrefaceDIFZPOSITIV = 0;
  PrefaceDIFZNEGATIV = 0;
  PrefaceDIFYPOSITIV = 0;
  PrefaceDIFYNEGATIV = 0;

  PrefaceKORDiameter = 0;
  PrefaceKORXSTO = 0;
  PrefaceKORZPOSITIV = 0;
  PrefaceKORZNEGATIV = 0;
  PrefaceKORYPOSITIV = 0;
  PrefaceKORYNEGATIV = 0;

  PrefaceCALCX = 0;
  PrefaceCALCY = 0;
  PrefaceCALCZ = 0;

  PrefaceOLDX = 0;
  PrefaceOLDY = 0;
  PrefaceOLDZ = 0;

  PrefaceFinalX = 0;
  PrefaceFinalY = 0;
  PrefaceFinalZ = 0;

}
