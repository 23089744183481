<div class="clr-row">

<div class="clr-col-1">
  </div>
  <div class="clr-col-10">
      <h1>Kunden:</h1>
<clr-datagrid>
        <clr-dg-column>Firma</clr-dg-column>
        <clr-dg-column>E-Mail</clr-dg-column>
        <clr-dg-column>Stadt</clr-dg-column>
        <clr-dg-column>Account Bestätigung</clr-dg-column>
    
        <clr-dg-row *ngFor="let order of users">
          <clr-dg-cell>{{order.payload.doc.data().companyname}}</clr-dg-cell>
          <clr-dg-cell>{{order.payload.doc.data().email}}</clr-dg-cell>
          <clr-dg-cell>{{order.payload.doc.data().postcode}} {{order.payload.doc.data().city}}</clr-dg-cell>
          <clr-dg-cell>
            <span *ngIf="!order.payload.doc.data().emailVerified" class="label label-danger">Keine Bestätigung</span>
            <span *ngIf="order.payload.doc.data().emailVerified" class="label label-success">Account bestätigt</span>
          </clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
  </div>
</div>