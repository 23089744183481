import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
import { MaterialInterface } from '../../types/material.interface';
import { UploadInterface } from '../../types/upload.interface';
import { UnitCountInterface } from '../../types/unit-count.interface';
import { BlankInterface } from '../../types/blank.interface';

@Component({
  selector: 'app-newworkingorder',
  templateUrl: './newworkingorder.component.html',
  styleUrls: ['./newworkingorder.component.scss'],
})
export class NewworkingorderComponent implements OnInit {
  constructor(readonly firebaseService: FirebaseService, private afs: AngularFirestore, public datePipe: DatePipe) {}

  range: number = 1;

  uploads: UploadInterface[] = [];
  uploads2: UploadInterface[] = [];

  deleteButton: boolean = false;
  backbutton: boolean = false;

  detailpage: UploadInterface | null = null;
  detailPageModal: boolean = false;
  detailPageModal2: boolean = false;
  showSecondBlank: boolean = true;
  showSecondBlankLater: boolean = false;
  submitbutton: boolean = false;
  dentalrohling: BlankInterface[] = [];
  documentID: string = '';
  workingblank: string = 'nicht festgelegt!';
  workingcharge: string = 'nicht festgelegt!';
  workingblank2: string = 'nicht festgelegt!';
  workingcharge2: string = 'nicht festgelegt!';
  workingbill: boolean = false;
  workingshippingcost: boolean = false;
  workingtime: string = '00:00';
  workingmachine: string = 'DT';
  workingtime2: string = '00:00';
  workingmachine2: string = 'Keine';
  laterwork: boolean = false;

  nightShiftToggle: boolean = false;

  unitCounts: UnitCountInterface = {
    orders: 0,
    total: 0,
    zircon: 0,
    wax: 0,
    pmma: 0,
    pmmaReduce: 0,
    nem: 0,
    nemReduce: 0,
    splint: 0,
    preFace: 0,
  };

  changemodaldata: UploadInterface | null = null;
  changemodal: boolean = false;
  vorname: string = 'test';
  nachname: string = 'test';
  werkstoff: string = 'test';
  marke: string = 'test';
  farbe: string = 'keine';
  techniker: string = 'test';
  beschreibung: string = 'test';
  materialList: MaterialInterface[] = [];

  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.getUnitNumbers();
    this.getColors();
  }

  getColors(): void {
    this.afs
      .collection<MaterialInterface>('materialbrands')
      .snapshotChanges()
      .subscribe((snapshots) => {
        this.materialList = snapshots.map((e) => {
          return e.payload.doc.data();
        });
      });
  }

  getColor(name: string): string {
    if (this.materialList != null && this.materialList.length > 0) {
      return this.materialList.find((e) => e.brandname === name).color;
    }
  }

  getUploads() {
    this.firebaseService.getworkingUploads().subscribe((v) => {
      const uploads: UploadInterface[] = [];
      v.forEach((x) => {
        const upload = x.payload.doc.data() as UploadInterface;
        upload.fireBaseDocId = x.payload.doc.id;
        uploads.push(upload);
      });
      this.uploads = uploads;
    });
  }

  getUploads2() {
    this.firebaseService.getworkingUploads2().subscribe((v) => {
      const uploads: UploadInterface[] = [];
      v.forEach((x) => {
        const upload = x.payload.doc.data() as UploadInterface;
        upload.fireBaseDocId = x.payload.doc.id;
        uploads.push(upload);
      });
      this.uploads2 = uploads;
    });
  }
  async moveBack(data: UploadInterface) {
    await this.firebaseService.movetoNew(data);
  }

  async deleteUploads(data: UploadInterface) {
    await this.firebaseService.deleteUploads(data);
  }

  openChangeModal(data: UploadInterface): void {
    this.documentID = data.fireBaseDocId;
    this.changemodaldata = data;
    this.vorname = data.patname;
    this.nachname = data.patlastname;
    this.werkstoff = data.materialtype;
    this.marke = data.materialbrand;
    this.farbe = data.teethcolor;
    this.techniker = data.technican;
    this.beschreibung = data.description;
    this.changemodal = true;
  }

  openDetailpage(data: UploadInterface): void {
    this.getMaterialRohlinge(data);
    this.documentID = data.fireBaseDocId;
    this.detailpage = data;
    this.detailPageModal = true;
  }

  openDetailpage2(data: UploadInterface): void {
    this.getMaterialRohlinge(data);
    this.documentID = data.fireBaseDocId;
    this.detailpage = data;
    this.detailPageModal2 = true;
  }

  toggleDisplay(): void {
    this.showSecondBlank = !this.showSecondBlank;
    this.showSecondBlankLater = !this.showSecondBlankLater;
    this.submitbutton = !this.submitbutton;
  }

  filterAndCountUploadByBrand(uploads: UploadInterface[], filter: string): number {
    const orderNumbers = uploads
      .filter(({ materialbrand }) => materialbrand.toLowerCase().includes(filter.toLowerCase()))
      .map((v) => +v.ordernumber);
    return orderNumbers.length === 0 ? 0 : orderNumbers.reduce((a, b) => a + b);
  }

  filterAndCountUploadByType(uploads: UploadInterface[], filter: string): number {
    const orderNumbers = uploads
      .filter(({ materialtype }) => materialtype.toLowerCase().includes(filter.toLowerCase()))
      .map((v) => +v.ordernumber);
    return orderNumbers.length === 0 ? 0 : orderNumbers.reduce((a, b) => a + b);
  }

  getUnitNumbers(): void {
    this.afs
      .collection('files', (ref) => {
        return ref.where('status', '==', 2);
      })
      .get()
      .subscribe((snaps) => {
        const uploads: UploadInterface[] = snaps.docs.map((snap) => snap.data() as UploadInterface);
        this.unitCounts.orders = uploads.length;
        this.unitCounts.splint =
          uploads.filter(({ description }) => description.toLowerCase().includes('schiene')).length +
          uploads.filter(({ description }) => description.toLowerCase().includes('schablone')).length;
        this.unitCounts.preFace = uploads.filter(({ materialbrand }) => materialbrand.endsWith('face')).length;
        this.unitCounts.zircon = this.filterAndCountUploadByType(uploads, 'Zirkon');
        this.unitCounts.nem = this.filterAndCountUploadByBrand(uploads, 'NEM');
        this.unitCounts.wax = this.filterAndCountUploadByBrand(uploads, 'Zirkon');
        this.unitCounts.pmma = this.filterAndCountUploadByBrand(uploads, 'MA');
        this.unitCounts.total = uploads.map((x) => +x.ordernumber).reduce((a, b) => a + b);
        this.unitCounts.pmmaReduce = this.unitCounts.pmma - this.unitCounts.splint;
        this.unitCounts.nemReduce = this.unitCounts.nem - this.unitCounts.preFace;
      });
  }

  isToday(milliseconds: string) {
    const date = new Date(+milliseconds * 1000);
    const today = new Date();
    return date.getFullYear() === today.getFullYear() && date.getMonth() === today.getMonth() && date.getDate() === today.getDate();
  }

  setFirstBlankCharge() {
    this.workingcharge = this.dentalrohling.find((v) => v.blankName === this.workingblank).cargeNumber;
  }
  setSecondBlankCharge() {
    this.workingcharge2 = this.dentalrohling.find((v) => v.blankName === this.workingblank2).cargeNumber;
  }

  getMaterialRohlinge(data: UploadInterface) {
    this.firebaseService.getMaterialRohlinge(data).subscribe((snapshots) => {
      this.dentalrohling = snapshots.map((e) => {
        return e.payload.doc.data();
      }) as BlankInterface[];
    });
  }

  async setNightShift(data: UploadInterface) {
    return await this.afs.collection('files').doc(data.fireBaseDocId).set(
      {
        nightshift: true,
      },
      {
        merge: true,
      },
    );
  }

  async disableNightshift(data: UploadInterface) {
    return await this.afs.collection('files').doc(data.fireBaseDocId).set(
      {
        nightshift: false,
      },
      {
        merge: true,
      },
    );
  }

  Submit() {
    this.detailPageModal = false;
    this.detailPageModal2 = false;
    const workingblank = this.workingblank;
    const workingcharge = this.workingcharge;
    const workingshippingcost = this.workingshippingcost;
    const workingbill = this.workingbill;
    const workingtime = this.workingtime;
    const workingmachine = this.workingmachine;
    const laterwork = this.laterwork;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 0;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;

    return this.afs.collection('files').doc(this.documentID).set(
      {
        status: 3,
        workingblank,
        workingcharge,
        workingbill,
        workingtime,
        workingmachine,
        workingshippingcost,
        workingmachineavaible,
        workingmachineavaible2,
        laterwork,
        blankmillingfinish,
        blankmillingfinish2,
      },
      {
        merge: true,
      },
    );
  }

  Submit2() {
    this.detailPageModal = false;
    this.detailPageModal2 = false;

    const workingblank = this.workingblank;
    const workingcharge = this.workingcharge;
    const workingshippingcost = this.workingshippingcost;
    const workingbill = this.workingbill;
    const workingtime = this.workingtime;
    const workingmachine = this.workingmachine;
    const workingblank2 = this.workingblank2;
    const workingcharge2 = this.workingcharge2;
    const workingtime2 = this.workingtime2;
    const workingmachine2 = this.workingmachine2;
    const laterwork = false;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 1;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;

    return this.afs.collection('files').doc(this.documentID).set(
      {
        status: 3,
        workingblank,
        workingcharge,
        workingbill,
        workingtime,
        workingmachine,
        workingshippingcost,
        workingcharge2,
        workingblank2,
        workingtime2,
        workingmachine2,
        workingmachineavaible,
        workingmachineavaible2,
        laterwork,
        blankmillingfinish,
        blankmillingfinish2,
      },
      {
        merge: true,
      },
    );
  }

  SubmitSingleSecondBlank() {
    this.detailPageModal = false;
    this.detailPageModal2 = false;
    const workingblank2 = this.workingblank2;
    const workingcharge2 = this.workingcharge2;
    const workingtime2 = this.workingtime2;
    const workingmachine2 = this.workingmachine2;
    const laterwork = false;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 1;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;
    return this.afs.collection('files').doc(this.documentID).set(
      {
        status: 3,
        workingcharge2,
        workingblank2,
        workingtime2,
        workingmachine2,
        workingmachineavaible,
        blankmillingfinish,
        blankmillingfinish2,
        workingmachineavaible2,
        laterwork,
      },
      {
        merge: true,
      },
    );
  }

  SubmitChange() {
    this.changemodal = false;
    const patname = this.vorname;
    const patlastname = this.nachname;
    const materialtype = this.werkstoff;
    const materialbrand = this.marke;
    const teethcolor = this.farbe;
    const technican = this.techniker;
    const description = this.beschreibung;

    return this.afs.collection('files').doc(this.documentID).set(
      {
        patname,
        patlastname,
        materialtype,
        materialbrand,
        teethcolor,
        technican,
        description,
      },
      {
        merge: true,
      },
    );
  }
}
