import {
  Component,
  OnInit
} from '@angular/core';
import {
  FirebaseService
} from '../shared/firebase.service';
import {
  AngularFirestore,
  AngularFirestoreCollection
} from '@angular/fire/firestore';

interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.scss']
})
export class ShippingComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
  ) { }

  isOpen = false;
  isOpen2 = false;
  showInfo = true;
  files;
  files2;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohlingcharge;

  technicansCollection: AngularFirestoreCollection<Materialandcolor>;
  technicans;

  detailpage;
  detailpagemodal = false;
  docID;

  workingbill: boolean = false;
  workingshippingcost: boolean = false;
  billnote: string = '';

  kamine: boolean = false;
  kamintechnican: string = 'keiner';
  kaminnumber: number = 0;

  technican;

  finishmodal: boolean = false;
  confirm;

  finishmodal2: boolean = false;
  confirm2;

  multiselect = false;
  selecteditems = [];

  enablemultiselect() {
    if (this.multiselect === true) {
      this.multiselect = false;
      this.selecteditems = [];
    } else {
      this.multiselect = true;
    }
  }
  addselectelement(item) {
    this.selecteditems.push(item);
  }

  removeselectedelement(item) {
    const index = this.selecteditems.indexOf(item);
    if (index > -1) {
      this.selecteditems.splice(index, 1);
    }
  }

  multidelete() {
    this.selecteditems.forEach(element => {
      this.multiSetfinalState(element);
      this.multiSetfinalState2(element);
    });

  }


  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.firebaseService.NewOrderCount();
    this.getTechnicans();
  }


  getColor(data) {
    (2)
    switch (data) {
      case 'Tizian NEM Fine':
        return '#10de2e';
      case 'ArgenP Clear':
        return '#ff6ea3';
      case 'priti Translucent':
        return '#07f6fa';
      case 'priti Opaque':
        return '#f79e05';
      case 'ArgenW blue':
        return '#fafa07';
      case 'Medentika Preface':
        return '#f70505';
      case 'Whitepeaks Copra Ti-4':
        return '#f70505';
    }
  }

  getTechnicans() {
    this.technicans = this.afs.collection('technicans', ref => {
      return ref.orderBy('tecnumber');
    }).valueChanges();
  }

  OpenDetailpage(data) {
    this.detailpage = data.payload.doc.data();
    this.workingbill = data.payload.doc.data().workingbill;
    console.log(this.workingbill);
    this.workingshippingcost = data.payload.doc.data().workingshippingcost;
    this.docID = data.payload.doc.id;
    this.billnote = data.payload.doc.data().billnote;
    console.log(this.billnote);
    this.kamine = data.payload.doc.data().kamine;
    this.kaminnumber = data.payload.doc.data().kaminnumber;
    this.kamintechnican = data.payload.doc.data().kamintechnican;
    this.openPage();
  }

  openPage() {
    this.detailpagemodal = true;
  }


  OpenConfirmPagefirst(data) {
    this.confirm = data.payload.doc.data();
    this.docID = data.payload.doc.id;
    this.openConfirmPage();
  }

  openConfirmPage() {
    this.finishmodal = true;
  }

  OpenConfirmPagesecond(data) {
    this.confirm2 = data.payload.doc.data();
    this.docID = data.payload.doc.id;
    this.openConfirmPage2();
  }

  openConfirmPage2() {
    this.finishmodal2 = true;
  }

  setnewSettings() {
    this.detailpagemodal = false;
    console.log(this.docID);
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        workingbill: this.workingbill,
        workingshippingcost: this.workingshippingcost,
        kamine: this.kamine,
        kamintechnican: this.kamintechnican,
        kaminnumber: this.kaminnumber,
        billnote: this.billnote
      }, {
        merge: true
      });
  }


  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
    this.firebaseService.getreadyUploads().subscribe(res => (this.files = res))

  getUploads2 = () =>
    this.firebaseService.getreadyUploads2().subscribe(res => (this.files2 = res))

  toggleDisplay() {
    this.showInfo = !this.showInfo;
  }



  SubmitIf(data) {
    if (data.payload.doc.data().blankmillingfinish === true && data.payload.doc.data().blankmillingfinish2 === true) {
      console.log('blankmillingfinish und blankmillingfinish2 auf true');
    } else {
      console.log('das gegenteil');
    }
  }

  SubmitIf2(data) {
    if (data.payload.doc.data().blankmillingfinish === true && data.payload.doc.data().blankmillingfinish2 === true) {
      console.log('2.blankmillingfinish und blankmillingfinish2 auf true');
    } else {
      console.log('2.das gegenteil');
    }
  }


  Submit1() {
    this.markCompleted2();
    this.markCompleted();
    this.finishmodal = false;
  }

  Submit2() {
    this.markCompleted3();
    this.markCompleted();
    this.finishmodal2 = false;
  }


  // TODO Nur auf neuen Status setzen, wenn wirklich beide Rohkling efertig sind

  // set to Status 11
  markCompleted() {
    this.SetfinalState();
  }
  // set blank1 ready
  markCompleted2() {
    this.SetfinalState2();
  }
  // // set blank2 ready
  markCompleted3() {
    this.SetfinalState3();
  }


  SetfinalState2() {
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        blank1ready: true,
        millingfinish: false
      }, {
        merge: true
      });

  }

  SetfinalState3() {
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        blank2ready: true,
        millingfinish2: false
      }, {
        merge: true
      });

  }
  SetfinalState() {
    return this.afs
      .collection('files')
      .doc(this.docID)
      .set({
        status: 11
      }, {
        merge: true
      });

  }

  multiSetfinalState3(item) {
    return this.afs
      .collection('files')
      .doc(item)
      .set({
        blank2ready: true,
        millingfinish2: false
      }, {
        merge: true
      });

  }
  multiSetfinalState(item) {
    return this.afs
      .collection('files')
      .doc(item)
      .set({
        status: 11
      }, {
        merge: true
      });

  }
  multiSetfinalState2(item) {
    return this.afs
      .collection('files')
      .doc(item)
      .set({
        blank1ready: true,
        millingfinish: false
      }, {
        merge: true
      });

  }

}
