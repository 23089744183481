import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import {AngularFirestore} from "@angular/fire/firestore";



@Component({
  selector: 'app-addwerkstoff',
  templateUrl: './addwerkstoff.component.html',
  styleUrls: ['./addwerkstoff.component.scss']
})
export class AddwerkstoffComponent implements OnInit {

  constructor(
    private firestore: AngularFirestore,
    public firebaseService: FirebaseService) { }



dentalmaterial;

dentalmaterialbrand;

dentalmaterialcolor;

dentalcolors;



materialtype = [];
materialbrand = [];
materialcolors = [];
colors = [];
color;


  ngOnInit() {
    this.getDentalmaterial();
    this.getDentalmaterialbrand();
    this.getDentalmaterialcolor();
    this.getDentaldentalcolor();
  }

onSubmitMaterialtype() {
  this.firebaseService.materialtypeform.value.materialtype = this.materialtype;
  const data = this.firebaseService.materialtypeform.value;

 this.firebaseService.createMaterialtype(data)
     .then(res => {
     });
}

setColor(color) {
    this.color = color;
}

  onColorSelected(color, id) {
    let selectedColor = color;
    console.log(selectedColor);
    console.log(id);
    this.firestore.collection('materialbrands')
      .doc(id)
      .set({
        color: selectedColor,
      }, {
        merge: true
      }).then()
  }

onSubmitMaterialbrand() {
  this.firebaseService.materialbrandform.value.materialbrand = this.materialbrand;
  const data = this.firebaseService.materialbrandform.value;
  console.log(this.firebaseService.materialbrandform.value);

 this.firebaseService.createMaterialbrand(data)
     .then(res => {
     });
}

onSubmitMaterialcolor() {
  this.firebaseService.materialcolorsform.value.color = this.materialcolors;
  const data = this.firebaseService.materialcolorsform.value;

 this.firebaseService.createMaterialcolor(data)
     .then(res => {
     });
}

onSubmitColor() {
  this.firebaseService.colorsform.value.colors = this.colors;
  const data = this.firebaseService.colorsform.value;
  console.log(this.firebaseService.colorsform.value);

 this.firebaseService.createColor(data)
     .then(res => {
     });
}

getDentalmaterial() {
  this.firebaseService
  .getDentalmaterial()
  .subscribe(res => (this.dentalmaterial = res));
 }

 getDentalmaterialbrand() {
  this.firebaseService
  .getDentalmaterialbrand()
  .subscribe(res => (this.dentalmaterialbrand = res));
 }

 getDentalmaterialcolor() {
  this.firebaseService
  .getDentalmaterialcolor()
  .subscribe(res => (this.dentalmaterialcolor = res));
 }

 getDentaldentalcolor() {
  this.firebaseService
  .getDentalcolor()
  .subscribe(res => (this.dentalcolors = res));
 }

addColor = color => this.materialcolors.push(color);

removeColor = color => {
    const index = this.materialcolors.indexOf(color);
    if (index > -1) { this.materialcolors.splice(index, 1); }
}

deletebrands = data => this.firebaseService.deletebrands(data);

deletecolors = data => this.firebaseService.deletecolors(data);

deletetypes = data => this.firebaseService.deletetypes(data);

}
