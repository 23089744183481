import { Component, OnInit } from '@angular/core';
import { FirebaseService } from 'src/app/shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-maschinen-mb1',
  templateUrl: './maschinen-mb1.component.html',
  styleUrls: ['./maschinen-mb1.component.scss']
})
export class MaschinenMB1Component implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

    HolderLeft;
    HolderLeft2;
    HolderRight;
    HolderRight2;

    HolderLeftBlank;
    HolderRightBlank;
    HolderLeftBlank2;
    HolderRightBlank2;

    millingtime;

    dateNow;
    timeleft;
    timedone;
    
  ngOnInit() {
    this.getHolderLeft();
    this.getHolderLeft2();
    this.getHolderRight();
    this.getHolderRight2();

    this.getHolderLeftBlank();
    this.getHolderRightBlank();
    this.getHolderLeftBlank2();
    this.getHolderRightBlank2();

    this.getTimenew();
    this.updatetime();
    this.setDatenow();

    console.log(this.HolderLeft2.doc.data().workingblank2);
  }


  getTimenew = () =>
  this.firebaseService.getmb1time().subscribe(res => (this.millingtime = res))

  getHolderLeft = () =>
  this.firebaseService.getMachineUploadsDTleft().subscribe(res => (this.HolderLeft = res))

  getHolderLeft2 = () =>
  this.firebaseService.getMachineUploadsDTleft2().subscribe(res => (this.HolderLeft2 = res))

  getHolderRight = () =>
  this.firebaseService.getMachineUploadsDTright().subscribe(res => (this.HolderRight = res))

  getHolderRight2 = () =>
  this.firebaseService.getMachineUploadsDTright2().subscribe(res => (this.HolderRight2 = res))

  getHolderLeftBlank = () =>
  this.firebaseService.getMachineUploadsDTleftBlank().subscribe(res => (this.HolderLeftBlank = res))

  getHolderRightBlank = () =>
  this.firebaseService.getMachineUploadsDTrightBlank().subscribe(res => (this.HolderRightBlank = res))

  getHolderLeftBlank2 = () =>
  this.firebaseService.getMachineUploadsDTleftBlank2().subscribe(res => (this.HolderLeftBlank2 = res))

  getHolderRightBlank2 = () =>
  this.firebaseService.getMachineUploadsDTrightBlank2().subscribe(res => (this.HolderRightBlank2 = res))

  setreadystate = data =>
  this.firebaseService.setreadystate(data)

  setreadystate2 = data =>
  this.firebaseService.setreadystate2(data)


  Submit(data) {
  this.setreadystate(data);
  }
  Submit2(data) {
  this.setreadystate2(data);
  }

  moveBack(data) {
    this.setmoveback(data);
  }

  moveBack2(data) {
    this.setmoveback2(data); 
  }

  setmoveback = data =>
  this.firebaseService.setMovebackStatus(data)

  setmoveback2 = data =>
  this.firebaseService.setMovebackStatus2(data)


  setTime(data) {
    this.timesetter(data);
    this.resttime (data)
  }

  timesetter = data =>
  this.firebaseService.Setmb1Timer(data)

  resttime (data) {
    this.timeleft = Date.now() - data.payload.doc.data().starttime;
  }


  updatetime() {
    this.dateNow = setInterval(() => { this.setDatenow(); }, 4000);
    
  }

  setDatenow () {
    this.dateNow = Date.now();
  }



}

