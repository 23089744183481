import {Component, Input, OnChanges} from '@angular/core';
import {Observable} from "rxjs";
import {AngularFirestore} from "@angular/fire/firestore";

@Component({
  selector: 'app-upload-table',
  templateUrl: './upload-table.component.html',
  styleUrls: ['./upload-table.component.scss']
})
export class UploadTableComponent implements OnChanges {

  public get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }


  @Input() uploads; // tslint:disable-line: no-input-rename

  selected;
  detailData;
  detailItemData;
  commentModal = false;
  labNote;
  detailState;

  constructor(
    private db: AngularFirestore,
  ) { }

  ngOnChanges(): void {
    if (this.detailData != null) {
      if (!(this.uploads.find(element => element.patientID == this.detailData.patientID))) {
        this.detailData = null;

      } else {
        this.detailData = this.uploads.find(element => element.patientID == this.detailData.patientID);
      }

    } else {
      if (this.uploads.length > 0) {
        this.detailData = this.uploads[0];
      }
    }
  }

  checkArray(item): boolean {
    const uploadArray = item.uploads
    if (uploadArray != null) {
      if (uploadArray.some(item => item.downloaded === false)) {
        return false;
      } else {
        return true;

      }
    }
    return false;
  }

  checkDate(uploads): boolean {
    const fourWeeks = Date.now() - 2419200000;
    if (uploads.status === OrderStatus.ARCHIVE) {
      return false;
    }
    if (uploads != null) {
      if (uploads.some(item => fourWeeks > item.onCreate)) {
        return true;
      } else {
        return false;
      }
    }
    return true;

  }



  async downloadFile(patient, item) {
    window.open(item.url);
    const uploads = patient.uploads;
    const upload = item;
    const elementsIndex = uploads.findIndex(element => element.onCreate == upload.onCreate);
    uploads[elementsIndex].downloaded = true;
    uploads[elementsIndex].downloadDate = Date.now();
    await this.db.collection('iosScans').doc(patient.patientID).set(
      {uploads},
      {merge: true}
    );
  }



  openModal(data,item) {
    this.detailData = data;
    this.detailItemData = item;
    this.commentModal = true;
  }

  openEditModal(data,item) {
    this.labNote = item.labNotes;
    this.detailData = data;
    this.detailItemData = item;
    this.commentModal = true;
  }




  async writeLabComment() {
    const uploads = this.detailData.uploads;
    const upload = this.detailItemData;
    console.log(uploads);
    console.log(upload);
    const elementsIndex = uploads.findIndex(element => element.onCreate == upload.onCreate);
    uploads[elementsIndex].labNotes = this.labNote;
    await this.db.collection('iosScans').doc(this.detailData.patientID).set(
      {uploads},
      {merge: true}
    ).then(
      this.labNote = null,
    );
    this.commentModal = false;
    this.detailItemData = null;
    this.detailData = null;

  }

  async setPatientStatus(patient, status: OrderStatus) {
    await this.db.collection('iosScans').doc(patient.patientID).set(
      {
        status: status,
        statusDate: Date.now()
      },
      {merge: true}
    );
  }

}

export enum OrderStatus {
  PENDING,
  NEW,
  COMPLETE,
  ARCHIVE
}

