import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {NgxPrintModule} from 'ngx-print';
import { ChartsModule } from 'ng2-charts';


import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AddblankComponent } from './rohlinge/addblank/addblank.component';
import { BlankListComponent } from './rohlinge/blank-list/blank-list.component';
import { FirebaseService } from './shared/firebase.service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { MatButtonModule} from '@angular/material/button';
import { ClarityModule } from '@clr/angular';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KundenListComponent } from './kunden/kunden-list/kunden-list.component';
import { UploadListComponent } from './uploads/upload-list/upload-list.component';
import { HeaderComponent } from './layout/header/header.component';
import { AddwerkstoffComponent } from './werkstoff/addwerkstoff/addwerkstoff.component';
import { WerkstoffListComponent } from './werkstoff/werkstoff-list/werkstoff-list.component';
import { MaschinenHomeComponent } from './maschinen/maschinen-home/maschinen-home.component';
import { MaschinenOrganicalComponent } from './maschinen/maschinen-organical/maschinen-organical.component';
import { MaschinenMB1Component } from './maschinen/maschinen-mb1/maschinen-mb1.component';
import { MaschinenMB2Component } from './maschinen/maschinen-mb2/maschinen-mb2.component';
import { WorkingorderComponent } from './workingorder/workingorder.component';
import { PrintingComponent } from './printing/printing.component';
import { BillingComponent } from './billing/billing.component';
import { PremillingComponent } from './premilling/premilling.component';
import { ShippingComponent } from './shipping/shipping.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { QRCodeModule } from 'angular2-qrcode';
import { OverviewComponent } from './overview/overview.component';
import { ZirconComponent } from './zircon/zircon.component';
import { DatePipe } from '@angular/common';
import { TechnicansComponent } from './technicans/technicans.component';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CountdownModule } from 'ngx-countdown';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ExcelService } from 'src/app/excel.service';
import { KamineComponent } from './kamine/kamine.component';
import { NewworkingorderComponent } from './newworkingorder/newworkingorder.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { TestdanielComponent } from './testdaniel/testdaniel.component';
import { HttpClientModule } from '@angular/common/http';
import { IosscansComponent } from './iosscans/iosscans.component';
import { UploadTableComponent } from './iosscans/upload-table/upload-table.component';
import { PrefacecalComponent } from './prefacecal/prefacecal.component';
import {ColorPickerModule} from "ngx-color-picker";







@NgModule({
  declarations: [
    AppComponent,
    AddblankComponent,
    BlankListComponent,
    KundenListComponent,
    UploadListComponent,
    HeaderComponent,
    AddwerkstoffComponent,
    WerkstoffListComponent,
    MaschinenHomeComponent,
    MaschinenOrganicalComponent,
    MaschinenMB1Component,
    MaschinenMB2Component,
    WorkingorderComponent,
    PrintingComponent,
    BillingComponent,
    PremillingComponent,
    ShippingComponent,
    OverviewComponent,
    ZirconComponent,
    TechnicansComponent,
    KamineComponent,
    NewworkingorderComponent,
    BarcodeComponent,
    StatisticsComponent,
    TestdanielComponent,
    IosscansComponent,
    UploadTableComponent,
    PrefacecalComponent,
  ],
  imports: [
    BrowserModule,
    ColorPickerModule,
    HttpClientModule,
    AppRoutingModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    MatButtonModule,
    ClarityModule,
    BrowserAnimationsModule,
    NgxBarcodeModule,
    QRCodeModule,
    FormsModule,
    CountdownModule,
    NgCircleProgressModule.forRoot({
      // set defaults here
      radius: 50,
      outerStrokeWidth: 4,
      innerStrokeWidth: 2,
      outerStrokeColor: "#78C000",
      innerStrokeColor: "#C7E596",
      animationDuration: 300,
      showTitle: true,
      showSubtitle: false,
      showUnits: true,
      showInnerStroke: true,
    }),
    NgxPrintModule,
    ChartsModule,
    NgxChartsModule
  ],
  providers:
  [DatePipe, ExcelService, FirebaseService, { provide: LOCALE_ID, useValue: 'de' },{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {
    registerLocaleData(localeDe);
  }
}
