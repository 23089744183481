import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-technicans',
  templateUrl: './technicans.component.html',
  styleUrls: ['./technicans.component.scss']
})
export class TechnicansComponent implements OnInit {

  constructor(
    private afs: AngularFirestore,
    public firebaseService: FirebaseService) { }

  technicansdata;

  technicans = [];
  detailpage;
  detailpagemodal: boolean = false;

  tecnumber: number;
  teclastname;
  tecname;

  tecnumber2;
  teclastname2;
  tecname2;

  docID;

  ngOnInit() {
    this.getTechnicans();
  }

  OpenDetailpage(data) {
    this.detailpage = data.payload.doc.data();
    this.tecname = data.payload.doc.data().tecname;
    this.teclastname = data.payload.doc.data().teclastname;
    this.tecnumber = data.payload.doc.data().tecnumber;
    this.docID = data.payload.doc.id;
    this.detailpagemodal = true;
  }

  setTechnican() {
    this.detailpagemodal = false;
    console.log(this.docID);
      return this.afs
          .collection('technicans')
          .doc(this.docID)
          .set({ tecnumber: this.tecnumber, teclastname: this.teclastname, tecname: this.tecname }, { merge: true});
  }

  getTechnicans() {
    this.firebaseService
    .getTechnicans()
    .subscribe(res => (this.technicansdata = res));
   }

  onSubmitTechnicans() {
    this.firebaseService.technicansform.value.technicans = this.technicans;
    const data = this.firebaseService.technicansform.value;

   this.firebaseService.createTechnicans(data)
       .then(res => {
       });
  }

}
