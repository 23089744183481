<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
      <h1>Grosser Zettel:</h1>
      <h3>Zeitraum Aufträge: {{ numberfiles }} </h3>
      <div class="clr-row">
          <div class="clr-col-2">
            <form clrForm clrLayout="horizontal">
                <clr-date-container>
                  <input type="date" clrDate name="demo" [(ngModel)]="firstdate">
                </clr-date-container>
              </form>
          </div>
          <div class="clr-col-2">
            <form clrForm clrLayout="horizontal">
                <clr-date-container>
                  <input type="date" clrDate name="demo" [(ngModel)]="seconddate">
                </clr-date-container>
              </form>
        </div>
        <div class="clr-col-2">
            <br>
                  <button [disabled]="false" (click)="convertUnixDates()" class="btn btn-primary btn-sm">Aufträge anzeigen<clr-icon shape="search"></clr-icon></button>
            </div>
        <div class="clr-col-2">
            <br>
            <button [disabled]="!export" class="btn btn-primary btn-sm" (click)="exportAsXLSX()">Export zu Excel  <clr-icon shape="export"></clr-icon></button>  
        </div>
      </div>
      <div>
      <clr-datagrid>
        <clr-dg-column>Back</clr-dg-column>
        <clr-dg-column>Set</clr-dg-column>
        <clr-dg-column>Negativdatum</clr-dg-column>
        <clr-dg-column [clrDgField]="'companyname'">Firma</clr-dg-column>
        <clr-dg-column [clrDgField]="'description'">Beschreibung</clr-dg-column>
        <clr-dg-column [clrDgField]="'patlastname'">Patientenname</clr-dg-column>
        <clr-dg-column [clrDgField]="'technican'">Techniker</clr-dg-column>
        <clr-dg-column>Auftragsdatum</clr-dg-column>
        <clr-dg-column>Lieferdatum</clr-dg-column>
        <clr-dg-column [clrDgField]="'materialtype'">Material</clr-dg-column>
        <clr-dg-column [clrDgField]="'materialbrand'">Materialmarke</clr-dg-column>
        <clr-dg-column [clrDgField]="'teethcolor'">Zahnfarbe</clr-dg-column>
        <clr-dg-column>Was wurde gesendet?</clr-dg-column>
        <clr-dg-column>Rechnung</clr-dg-column>
        <clr-dg-column>Versand</clr-dg-column>
        <clr-dg-column>3D-Druck</clr-dg-column>
        <clr-dg-column [clrDgField]="'workingblank'">Blank 1</clr-dg-column>
        <clr-dg-column [clrDgField]="'workingcharge'">Charge 1</clr-dg-column>
        <clr-dg-column [clrDgField]="'workingblank2'">Blank 2</clr-dg-column>
        <clr-dg-column [clrDgField]="'workingcharge2'">Charge 2</clr-dg-column>
        <clr-dg-column>Anmerkungen</clr-dg-column>
        <clr-dg-column>Kommentar</clr-dg-column>
        <clr-dg-column>Status</clr-dg-column>
    
        <clr-dg-row *clrDgItems="let order of exportfiles">
            <clr-dg-cell><clr-icon (click)="pullback(order)" shape="redo" size="24"></clr-icon></clr-dg-cell>
            <clr-dg-cell><clr-icon (click)="setnewdate(order)" shape="calendar" size="24"></clr-icon></clr-dg-cell>
            <clr-dg-cell>{{ order.negativdate }}</clr-dg-cell>
            <clr-dg-cell>{{ order.companyname }}</clr-dg-cell>
            <clr-dg-cell>{{ order.description }}</clr-dg-cell>
            <clr-dg-cell>{{ order.patlastname }}, {{ order.patname }}</clr-dg-cell>
            <clr-dg-cell>{{ order.technican }}</clr-dg-cell>
            <clr-dg-cell>{{ order.date | date:'short' }}</clr-dg-cell>
            <clr-dg-cell>
                <div *ngIf="order.deliverydate == latest_date">
                    <span class="label label-orange">HEUTE</span>
                </div>
                {{ order.deliverydate }}
            </clr-dg-cell>
            <clr-dg-cell>{{ order.materialtype }}</clr-dg-cell>
            <clr-dg-cell>{{ order.materialbrand }}</clr-dg-cell>
            <clr-dg-cell>{{ order.teethcolor }}</clr-dg-cell>
            <clr-dg-cell>{{ order.whattosend }}</clr-dg-cell>
            <clr-dg-cell>{{ order.billready }}</clr-dg-cell>
            <clr-dg-cell>{{ order.workingshippingcost }}</clr-dg-cell>
            <clr-dg-cell>
                    <span *ngIf="order.printstatus == 0"></span>
                    <span *ngIf="order.printstatus == 1" class="label label-danger">Noch nicht gedruckt</span>
                    <span *ngIf="order.printstatus == 2" class="label label-warning">Vorbereitet Formlabs</span>
                    <span *ngIf="order.printstatus == 3" class="label label-warning">Vorbereitet Anycubic</span>
                    <span *ngIf="order.printstatus == 4" class="label label-info">Druckt auf Formlabs</span>
                    <span *ngIf="order.printstatus == 5" class="label label-info">Druckt auf Anycubic</span>
                    <span *ngIf="order.printstatus == 6" class="label label-success">3D-Druck abgeschlossen</span>
            </clr-dg-cell>
            <clr-dg-cell>{{ order.workingblank }}</clr-dg-cell>
            <clr-dg-cell>{{ order.workingcharge }}</clr-dg-cell>
            <clr-dg-cell>{{ order.workingblank2 }}</clr-dg-cell>
            <clr-dg-cell>{{ order.workingcharge2 }}</clr-dg-cell>
            <clr-dg-cell><clr-signpost><button class="btn btn-link" clrSignpostTrigger><clr-icon shape="details"></clr-icon></button>
            <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>{{ order.notes }}</clr-signpost-content></clr-signpost></clr-dg-cell>
            <clr-dg-cell><clr-signpost><button class="btn btn-link" clrSignpostTrigger><clr-icon shape="details"></clr-icon></button>
            <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>{{ order.comment }}</clr-signpost-content></clr-signpost></clr-dg-cell>
            <clr-dg-cell>{{ order.status }}</clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</div>
</div>
</div>
