<div *ngIf="size > 0" class="alert alert-app-level alert-info" role="alert">
        <div class="alert-items">
            <div class="alert-item static">
                <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="info-circle"></clr-icon>
                </div>
                <div class="alert-text">
                    Es ist ein neuer Auftrag verfügbar!
                </div>
                <div class="alert-actions">
                    <button [routerLink]="'/uploads'" class="btn alert-action">Zu den neuen Aufträgen</button>
                </div>
            </div>
        </div>
    </div>
        <clr-header class="header-6">
                <div class="branding">
                    <a [routerLink]="'/dashboard'" class="nav-link">
                        <clr-icon shape="envelope"></clr-icon>
                        <span class="title">Dental-Technik Hamm</span>
                    </a>
                </div>
                <div class="header-nav" [clr-nav-level]="1">
                        <a (click)="getAllNumbers()" [routerLink]="'/uploads'" routerLinkActive="active" class="nav-link nav-text">Neu! <span class="badge badge-orange">{{ size }}</span></a>
                  <a (click)="getAllNumbers()" [routerLink]="'/ios'" routerLinkActive="active" class="nav-link nav-text">IOS <span class="badge badge-orange">{{ iosnumber }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/working'" routerLinkActive="active" class="nav-link nav-text">Aufträge <span class="badge badge-orange">{{ worknumber }}</span><span class="badge badge-blue"> {{ worknumberUnits }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/vorbereitung'" routerLinkActive="active" class="nav-link nav-text">Ready <span class="badge badge-orange">{{ preparetotal }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/printing'" routerLinkActive="active" class="nav-link nav-text">3D-Druck <span class="badge badge-orange">{{ printnumber }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/rechnungen'" routerLinkActive="active" class="nav-link nav-text">Rechnungen <span class="badge badge-orange">{{ billingnumber }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/kamine'" routerLinkActive="active" class="nav-link nav-text">Kamine <span class="badge badge-orange">{{ kaminnumber }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/Zircon'" routerLinkActive="active" class="nav-link nav-text">Zirkon <span class="badge badge-orange">{{ zirkonnumbertotal }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/maschinenhome'" routerLinkActive="active" class="nav-link nav-text">Maschinen <span class="badge badge-orange">{{ millingnumber }}</span><span class="badge badge-blue"> {{ millingUnitnumber }}</span></a>
                        <a (click)="getAllNumbers()" [routerLink]="'/versand'" routerLinkActive="active" class="nav-link nav-text">Versand <span class="badge badge-orange">{{ shippingnumbertotal }}</span></a>
                    </div>
                <div class="header-actions">
                    <clr-dropdown>
                        <button clrDropdownTrigger>
                            <clr-icon shape="cog" size="24"></clr-icon>
                        </button>
                        <clr-dropdown-menu *clrIfOpen clrPosition="bottom-right">
                            <a [routerLink]="'/werkstoff'" clrDropdownItem>Werkstoff</a>
                            <a [routerLink]="'/rohlinge'" clrDropdownItem>Rohlinge</a>
                            <a [routerLink]="'/Zircon'" clrDropdownItem>Sinter-Ofen</a>
                            <a [routerLink]="'/kunden'" clrDropdownItem>Kunden</a>
                            <a [routerLink]="'/technicans'" clrDropdownItem>Technikerliste</a>
                            <a [routerLink]="'/Ubersicht'" clrDropdownItem>Übersicht</a>
                            <a [routerLink]="'/statistik'" clrDropdownItem>Statistik</a>
                          <a (click)="basic = !basic" clrDropdownItem>Preface</a>
                            <a [routerLink]="'/newworking'" clrDropdownItem></a>
                        </clr-dropdown-menu>
                    </clr-dropdown>
                </div>
            </clr-header>


<clr-modal [(clrModalOpen)]="basic">
  <h3 class="modal-title">Preface Berechnung</h3>
  <div class="modal-body">
    <app-prefacecal></app-prefacecal>
  </div>
</clr-modal>
