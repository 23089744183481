<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
      <h1>Statistik:</h1>
      <h3>Zeitraum Aufträge: {{ numberfiles }} </h3>
      <div class="clr-row">
          <div class="clr-col-2">
            <form clrForm clrLayout="horizontal">
                <clr-date-container>
                  <input type="date" clrDate name="demo" [(ngModel)]="firstdate">
                </clr-date-container>
              </form>
          </div>
          <div class="clr-col-2">
            <form clrForm clrLayout="horizontal">
                <clr-date-container>
                  <input type="date" clrDate name="demo" [(ngModel)]="seconddate">
                </clr-date-container>
              </form>
        </div>
        <div class="clr-col-2">
            <br>
                  <button [disabled]="false" (click)="convertUnixDates()" class="btn btn-primary btn-sm">Aufträge anzeigen<clr-icon shape="search"></clr-icon></button>
                  <button [disabled]="false" (click)="onlyUniqueFilter()" class="btn btn-primary btn-sm">Pie anzeigen<clr-icon shape="search"></clr-icon></button>
            </div>
        <div class="clr-col-2">
            <br>
            <button [disabled]="!export" class="btn btn-primary btn-sm" (click)="exportAsXLSX()">Export zu Excel  <clr-icon shape="export"></clr-icon></button>  
        </div>
      </div>
      <ngx-charts-bar-vertical
      [view]="view"
      [scheme]="colorScheme"
      [results]="testdata2"
      [gradient]="gradient"
      [xAxis]="showXAxis"
      [yAxis]="showYAxis"
      [legend]="showLegend"
      [showXAxisLabel]="showXAxisLabel"
      [showYAxisLabel]="showYAxisLabel"
      [xAxisLabel]="xAxisLabel"
      [yAxisLabel]="yAxisLabel">
      </ngx-charts-bar-vertical>
</div>
</div>
