<div class="clr-row">
  <div class="clr-col-1">
  </div>
  <div class="clr-col-10">
    <h1>Aufträge: <strong>{{size}} </strong></h1><a (click)="toggleDisplay()" class="btn btn-sm">
      <clr-icon shape="download"></clr-icon>Mehr Infos
    </a>
    <div class="clr-row">
      <div *ngFor="let order of files" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
        <div class="card">
          <div class="card-header">
            <div *ngIf="order.payload.doc.data().millagain" class="alert alert-danger alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                  <div class="alert-text">
                    Arbeit muss wiederholt gefräst werden!
                  </div>
                </div>
              </div>
              <br>
            </div>
            <div *ngIf="order.payload.doc.data().deliverydate == latest_date" class="alert alert-warning alert-sm"
              role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">
                    Liefertermin ist HEUTE!
                  </div>
                </div>
              </div>
              <br>
            </div>
            <div *ngIf="!order.payload.doc.data().notes == ''" class="alert alert-warning alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">
                    <span> {{ order.payload.doc.data().notes }}</span>
                  </div>
                </div>
              </div>
              <br>
            </div>
            <strong>{{order.payload.doc.data().companyname}}</strong>
          </div>
          <div class="card-block">
            <div class="card-title">

              {{order.payload.doc.data().description}} <span
                class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
            </div>
            <div style="height: 5px" [ngStyle]="{'background-color':getColor(order.payload.doc.data().materialbrand)}">
            </div>
            <hr>
            <div class="card-text">
              <table class="table">
                <tbody>
                  <tr>
                    <td>Nachname
                      <br>
                      <strong>{{ order.payload.doc.data().patlastname }}</strong>
                    </td>
                    <td>Vorname
                      <br>
                      <strong>{{ order.payload.doc.data().patname }}</strong>
                  </tr>
                  <tr>
                    <td>Werkstoff
                      <br>
                      <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                    <td>Farbe
                      <br>
                      <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                  </tr>
                  <tr>
                    <td>Techniker
                      <br>
                      <strong>{{ order.payload.doc.data().technican }}</strong>
                    <td>Lieferdatum
                      <br>
                      <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <button (click)="DisplayModal1(order)" class="btn btn-primary btn-block">Für Fräsen vorbereiten</button>
          <div *ngIf="order.payload.doc.data().docID == ModalID">
            <clr-modal [(clrModalOpen)]="isOpen">
              <h3 class="modal-title">Vorbereitung Fräsen</h3>
              <div class="modal-body">
                <p>Machen Sie bitte folgende Angaben um das Modell für die Fräsung freizugeben:</p>
                <a (click)="toggleDisplay2()" class="label label-purple clickable">
                  2 Rohlinge
                </a>
                <form clrForm [formGroup]="this.firebaseService.workingform">
                  <clr-select-container>
                    <label>Rohling</label>
                    <select clrSelect formControlName="workingblank" (ngModelChange)="getOptions($event)" clrSelect
                      name="options">
                      <option *ngFor="let order of dentalrohling" value="{{order.payload.doc.data().blankName}}">
                        {{order.payload.doc.data().blankName}}</option>
                    </select>
                  </clr-select-container>
                  <clr-select-container>
                    <label>Charge</label>
                    <select clrSelect formControlName="workingcharge" clrSelect name="options">
                      <option *ngFor="let order of dentalrohlingcharge"
                        value="{{order.payload.doc.data().cargeNumber}}">
                        {{order.payload.doc.data().cargeNumber}}</option>
                    </select>
                  </clr-select-container>
                  <clr-select-container>
                    <label>Maschine</label>
                    <select formControlName='workingmachine' clrSelect name="options" required>
                      <option value="DT">DT</option>
                      <option value="DCT">DCT</option>
                      <option value="Organical">Organical</option>
                    </select>
                  </clr-select-container>
                  <clr-input-container>
                    <label>Zeit</label>
                    <input formControlName='workingtime' type="time" class="formControl" clrInput />
                  </clr-input-container>
                  <div [hidden]="showsecondblanklater">
                    <clr-toggle-container clrInline>
                      <clr-toggle-wrapper>
                        <input type="checkbox" clrToggle name="options" formControlName="laterwork" />
                      </clr-toggle-wrapper>
                      <clr-control-helper>2te Rohling später</clr-control-helper>
                    </clr-toggle-container>
                  </div>
                  <div [hidden]="showsecondblank">
                    <hr>
                    <clr-select-container>
                      <label>2 Rohling</label>
                      <select clrSelect formControlName="workingblank2" (ngModelChange)="getOptions2($event)" clrSelect
                        name="options">
                        <option *ngFor="let order of dentalrohling" value="{{order.payload.doc.data().blankName}}">
                          {{order.payload.doc.data().blankName}}</option>
                      </select>
                    </clr-select-container>
                    <clr-select-container>
                      <label>2 Charge</label>
                      <select clrSelect formControlName="workingcharge2" clrSelect name="options">
                        <option *ngFor="let order of dentalrohlingcharge2"
                          value="{{order.payload.doc.data().cargeNumber}}">
                          {{order.payload.doc.data().cargeNumber}}</option>
                      </select>
                    </clr-select-container>
                    <clr-select-container>
                      <label>2 Maschine</label>
                      <select formControlName='workingmachine2' clrSelect name="options" required>
                        <option value="DT">DT</option>
                        <option value="DCT">DCT</option>
                        <option value="Organical">Organical</option>
                      </select>
                    </clr-select-container>
                    <clr-input-container>
                      <label>2 Zeit</label>
                      <input formControlName='workingtime2' type="time" class="formControl" clrInput />
                    </clr-input-container>
                  </div>
                  <br>
                  <hr>
                  <br>
                  <span><strong>Angaben für die Rechnungserstellung</strong></span>
                  <clr-toggle-container clrInline>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle name="options" formControlName="workingbill" />
                      <label>Rechnung</label>
                    </clr-toggle-wrapper>
                    <clr-toggle-wrapper>
                      <input type="checkbox" clrToggle name="options" formControlName="workingshippingcost" />
                      <label>Versand</label>
                    </clr-toggle-wrapper>
                  </clr-toggle-container>
                </form>
              </div>
              <div [hidden]="submitbutton" class="modal-footer">
                <button (click)="isOpen = !isOpen" type="button" class="btn btn-outline">Abbrechen</button>
                <button type="button" class="btn btn-primary" (click)="Submit(order)">Freigeben</button>
              </div>
              <div [hidden]="!submitbutton" class="modal-footer">
                <button (click)="isOpen = !isOpen" type="button" class="btn btn-outline">Abbrechen</button>
                <button type="button" class="btn btn-primary" (click)="Submit4(order)">2 Rohlinge Freigeben</button>
              </div>
            </clr-modal>
          </div>
          <div class="card-footer">
            <a (click)="moveBack(order)" class="btn btn-sm">
              <clr-icon shape="undo"></clr-icon>Zurück
            </a>
            <a (click)="DisplayModal2(order)" class="btn btn-danger btn-sm">
              <clr-icon shape="trash"></clr-icon>Löschen
            </a>
            <div *ngIf="order.payload.doc.data().docID == ModalID">
              <clr-modal [(clrModalOpen)]="isOpen2">
                <h3 class="modal-title">Auftrag Löschen</h3>
                <div class="modal-body">
                  <div class="alert alert-danger" role="alert">
                    <div class="alert-items">
                      <div class="alert-item static">
                        <div class="alert-icon-wrapper">
                          <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                        </div>
                        <span class="alert-text">
                          Der Auftrag <strong>"{{order.payload.doc.data().description}}"</strong> mit Datei
                          <strong>"{{ order.payload.doc.data().path }}"</strong> werden unwiederruflich gelöscht!
                        </span>
                      </div>
                    </div>
                  </div>
                  <p>Möchten Sie den ausgewählten Auftrags wirklich löschen?</p>
                </div>
                <div class="modal-footer">
                  <button (click)="isOpen2 = !isOpen2" type="button" class="btn btn-outline">Abbrechen</button>
                  <button type="button" class="btn btn-danger" (click)="SubmitDelete(order)">Löschen</button>
                </div>
              </clr-modal>
            </div>
          </div>
        </div>
      </div>
      <div *ngFor="let order of files2" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
        <div class="card">
          <div class="card-header">
            <strong>{{order.payload.doc.data().companyname}} <span class="label label-purple">Teil 2</span></strong>
          </div>
          <div class="card-block">
            <div class="card-title">
              {{order.payload.doc.data().description}} <span
                class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
            </div>
            <div style="height: 5px" [ngStyle]="{'background-color':getColor(order.payload.doc.data().materialbrand)}">
            </div>
          </div>
          <hr>
          <div class="card-text">
            <table class="table">
              <tbody>
                <tr>
                  <td>Vorname
                    <br>
                    <strong>{{ order.payload.doc.data().patname }}</strong>
                  </td>
                  <td>Nachname
                    <br>
                    <strong>{{ order.payload.doc.data().patlastname }}</strong>
                </tr>
                <tr>
                  <td>Werkstoff
                    <br>
                    <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                  <td>Farbe
                    <br>
                    <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                </tr>
                <tr>
                  <td>Techniker
                    <br>
                    <strong>{{ order.payload.doc.data().technican }}</strong>
                  <td>Lieferdatum
                    <br>
                    <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <button (click)="DisplayModal4(order)" class="btn btn-primary btn-block">Teil 2 Für Fräsen vorbereiten</button>
        <div *ngIf="order.payload.doc.data().docID == ModalID">


          <clr-modal [(clrModalOpen)]="isOpen4">
            <h3 class="modal-title">Vorbereitung Fräsen</h3>
            <div class="modal-body">
              <p>Machen Sie bitte folgende Angaben um das Modell für die Fräsung freizugeben:</p>
              <form clrForm [formGroup]="this.firebaseService.secondworkingform">
                <div>
                  <hr>
                  <clr-select-container>
                    <label>Rohling</label>
                    <select clrSelect formControlName="workingblank2" (ngModelChange)="getOptions3($event)" clrSelect
                      name="options">
                      <option *ngFor="let order of dentalrohling" value="{{order.payload.doc.data().blankName}}">
                        {{order.payload.doc.data().blankName}}</option>
                    </select>
                  </clr-select-container>
                  <clr-select-container>
                    <label>Charge</label>
                    <select clrSelect formControlName="workingcharge2" clrSelect name="options">
                      <option *ngFor="let order of dentalrohlingcharge3"
                        value="{{order.payload.doc.data().cargeNumber}}">
                        {{order.payload.doc.data().cargeNumber}}</option>
                    </select>
                  </clr-select-container>
                  <clr-select-container>
                    <label>Maschine</label>
                    <select formControlName='workingmachine2' clrSelect name="options" required>
                      <option value="DT">DT</option>
                      <option value="DCT">DCT</option>
                      <option value="Organical">Organical</option>
                    </select>
                  </clr-select-container>
                  <clr-input-container>
                    <label>Zeit</label>
                    <input formControlName='workingtime2' type="time" class="formControl" clrInput />
                  </clr-input-container>
                </div>
                <br>
              </form>
            </div>
            <div class="modal-footer">
              <button (click)="isOpen4 = !isOpen4" type="button" class="btn btn-outline">Abbrechen</button>
              <button type="button" class="btn btn-primary" (click)="Submit3(order)">Freigeben</button>
            </div>
          </clr-modal>
        </div>
        <div class="card-footer">
          <a (click)="moveBack(order)" class="btn btn-sm">
            <clr-icon shape="undo"></clr-icon>Zurück
          </a>
          <a (click)="DisplayModal2(order)" class="btn btn-danger btn-sm">
            <clr-icon shape="trash"></clr-icon>Löschen2
          </a>
          <div *ngIf="order.payload.doc.data().docID == ModalID">
            <clr-modal [(clrModalOpen)]="isOpen2">
              <h3 class="modal-title">Auftrag Löschen</h3>
              <div class="modal-body">
                <div class="alert alert-danger" role="alert">
                  <div class="alert-items">
                    <div class="alert-item static">
                      <div class="alert-icon-wrapper">
                        <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                      </div>
                      <span class="alert-text">
                        Der Auftrag <strong>"{{order.payload.doc.data().description}}"</strong> mit Datei
                        <strong>"{{ order.payload.doc.data().path }}"</strong> werden unwiederruflich gelöscht!
                      </span>
                    </div>
                  </div>
                </div>
                <p>Möchten Sie den ausgewählten Auftrags wirklich löschen?</p>
              </div>
              <div class="modal-footer">
                <button (click)="isOpen2 = !isOpen2" type="button" class="btn btn-outline">Abbrechen</button>
                <button type="button" class="btn btn-danger" (click)="SubmitDelete(order)">Löschen</button>
              </div>
            </clr-modal>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


