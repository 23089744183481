import {
  Injectable,
  Query
} from '@angular/core';
import {
  FormControl,
  FormGroup
} from '@angular/forms';
import {
  AngularFirestore, DocumentChangeAction
} from '@angular/fire/firestore';
import {
  registerLocaleData
} from '@angular/common';
import {ColorInterface} from '../../types/color.interface';
import {Observable} from 'rxjs';
import {UploadInterface} from '../../types/upload.interface';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  size;
  blankchargenumber;
  laterwork2: boolean;
  negativdate2;



  constructor(
    private firestore: AngularFirestore) {}

  form = new FormGroup({
    testdate: new FormControl(''),
    werkstoffName: new FormControl(''),
    shortName: new FormControl(''),
    blankName: new FormControl(''),
    cargeNumber: new FormControl(''),
    blankmaterial: new FormControl(''),
  });
  blankform = new FormGroup({
    blankName: new FormControl(''),
    cargeNumber: new FormControl(''),
    blankmaterial: new FormControl(''),
  });
  technicansform = new FormGroup({
    tecname: new FormControl(''),
    teclastname: new FormControl(''),
    tecnumber: new FormControl(''),
  });
  materialtypeform = new FormGroup({
    name: new FormControl(''),
    typekey: new FormControl(''),
  });
  materialbrandform = new FormGroup({
    brandname: new FormControl(''),
    typebrandkey: new FormControl(''),
  });
  materialcolorsform = new FormGroup({
    brand: new FormControl(''),
  });
  colorsform = new FormGroup({
    color: new FormControl(''),
  });
  commentform = new FormGroup({
    extracomment: new FormControl(''),
  });
  billingform = new FormGroup({
    billfinish: new FormControl(''),
  });
  workingform = new FormGroup({
    workingblank: new FormControl(''),
    workingcharge: new FormControl(''),
    workingblank2: new FormControl(''),
    workingcharge2: new FormControl(''),
    workingbill: new FormControl(''),
    workingshippingcost: new FormControl(''),
    workingtime: new FormControl(''),
    workingmachine: new FormControl(''),
    workingholder: new FormControl(''),
    workingtime2: new FormControl(''),
    workingmachine2: new FormControl(''),
    workingholder2: new FormControl(''),
    laterwork: new FormControl(''),
  });
  secondworkingform = new FormGroup({
    workingblank2: new FormControl(''),
    workingcharge2: new FormControl(''),
    workingtime2: new FormControl(''),
    workingmachine2: new FormControl(''),
    workingholder2: new FormControl(''),
  });
  mb1timerform = new FormGroup({
    millingtime: new FormControl(''),
  });
  mb2timerform = new FormGroup({
    millingtime: new FormControl(''),
  });
  orgtimerform = new FormGroup({
    millingtime: new FormControl(''),
  });

  getColor(data) {


  }

  createDentalmaterial(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('dentalmaterial')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }
  createTechnicans(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('technicans')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }
  createRohlinge(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('rohlinge')
        .add(
          {
            blankName: data.blankName,
            blankmaterial: data.blankmaterial,
            blankStatus: true,
            cargeNumber: data.cargeNumber
          }

          )
        .then(res => {}, err => reject(err));
    });
  }

  copyMessage(val: string){
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  createMaterialtype(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('materialtypes')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }

  createMaterialbrand(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('materialbrands')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }



  createMaterialcolor(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('materialcolors')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }

  createColor(data) {
    return new Promise < any > ((resolve, reject) => {
      this.firestore
        .collection('dentalcolors')
        .add(data)
        .then(res => {}, err => reject(err));
    });
  }

  getRohlinge() {
    return this.firestore.collection('rohlinge', ref => {
      return ref.orderBy('blankName');
    }).snapshotChanges();
  }

  getallactivRohlinge() {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankStatus', '==', true).orderBy('blankName');
    }).snapshotChanges();
  }

  getallinactivRohlinge() {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankStatus', '==', false).orderBy('blankName');
    }).snapshotChanges();
  }


  getMaterialRohlinge(data: UploadInterface) {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankmaterial', '==', data.materialtype).where('blankStatus', '==', true).orderBy('blankName');
    }).snapshotChanges();
  }

  getTechnicans() {
    return this.firestore.collection('technicans', ref => {
      return ref.orderBy('tecnumber');
    }).snapshotChanges();
  }

  getAdress(data) {
    console.log(data.payload.doc.data().UserID);
    return this.firestore.collection('users', ref => {
      return ref.where('uid', '==', data.payload.doc.data().UserID);
    }).snapshotChanges();
  }

  getChargenumber() {
    console.log(this.workingform.get('workingblank').value);
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankName', '==', this.workingform.get('workingblank').value);
    }).snapshotChanges();
  }

  getChargenumber2() {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankName', '==', this.workingform.get('workingblank2').value);
    }).snapshotChanges();
  }
  getChargenumber3() {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankName', '==', this.secondworkingform.get('workingblank2').value);
    }).snapshotChanges();
  }


  getTitanRohlinge2() {
    this.firestore.collection('rohlinge');
  }


  getDentalmaterial() {
    return this.firestore.collection('materialtypes').snapshotChanges();
  }

  getMaterialtypeRohling(data) {
    return this.firestore.collection('rohlinge', ref => {
      return ref.where('blankmaterial', '==', data.payload.doc.data().name).where('blankStatus', '==', true).orderBy('blankName');
    }).snapshotChanges();
  }

  getDentalmaterialbrand() {
    return this.firestore.collection('materialbrands').snapshotChanges();
  }

  getDentalmaterialcolor(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection('materialcolors').snapshotChanges();
  }

  getDentalcolor() {
    return this.firestore.collection('dentalcolors', ref => {
      return ref.orderBy('color');
    }).snapshotChanges();
  }

  getUsers() {
    return this.firestore.collection('users').snapshotChanges();
  }

  getUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 1);
    }).snapshotChanges();
  }

  getallUploads() {
    return this.firestore.collection('files', ref => {
      return ref.orderBy('status');
    }).snapshotChanges();
  }

  getworkingUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 2).orderBy('deliverydate');
    }).snapshotChanges();
  }

  getworkingPrinting() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '>', 1).where('printing', '==', true);
    }).snapshotChanges();
  }

  getworkingUploads2() {
    return this.firestore.collection('files', ref => {
      return ref.where('laterwork', '==', true).orderBy('deliverydate');
    }).snapshotChanges();
  }

  PrepareMillingUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 3);
    }).snapshotChanges();
  }

  PrepareMillingUploads2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachineavaible2', '==', 1);
    }).snapshotChanges();
  }

  getbillingUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingbill', '==', true).where('laterwork', '==', false).where('millingfinish', '==', true);
    }).snapshotChanges();
  }

  getreadyUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish', '==', true)
        .where('blankmillingfinish', '==', true).where('zircon', '==', false);
    }).snapshotChanges();
  }

  getreadyUploads2() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish2', '==', true)
        .where('blankmillingfinish2', '==', true).where('zircon', '==', false);
    }).snapshotChanges();
  }

  getreadyZirconUploads() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish', '==', true)
        .where('blankmillingfinish', '==', true).where('zircon', '==', true).where('materialtype', '==', 'Zirkon');
    }).snapshotChanges();
  }

  getreadyZirconUploads2() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish2', '==', true)
        .where('blankmillingfinish2', '==', true).where('zircon', '==', true).where('materialtype', '==', 'Zirkon');
    }).snapshotChanges();
  }

  getMachineUploadsDTleftBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDTrightBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDTleftBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDTrightBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }
  getMachineUploadsDTleft() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsDTright() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsDCTleft() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsDCTright() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }


  getMachineUploadsDTright2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsDCTleftBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDCTrightBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'DCT').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDCTleftBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDCTrightBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsORGleft() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsORGright() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0);
    }).snapshotChanges();
  }
  getMachineUploadsORGleftBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'L').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsORGrightBlank() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine', '==', 'Organical').where('blankmillingfinish', '==', false)
        .where('workingholder', '==', 'R').where('workingmachineavaible', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsORGleftBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsORGrightBlank2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0).limit(1);
    }).snapshotChanges();
  }

  getMachineUploadsDTleft2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }



  getMachineUploadsDCTleft2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsDCTright2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'DCT').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsORGleft2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'L').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }

  getMachineUploadsORGright2(): Observable<DocumentChangeAction<unknown>[]> {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachine2', '==', 'Organical').where('blankmillingfinish2', '==', false)
        .where('workingholder2', '==', 'R').where('workingmachineavaible2', '==', 0);
    }).snapshotChanges();
  }


  getmb1time() {
    return this.firestore.collection('timer', ref => {
      return ref.where('machine', '==', 'mb1');
    }).snapshotChanges();
  }

  getmb2time() {
    return this.firestore.collection('timer', ref => {
      return ref.where('machine', '==', 'mb2');
    }).snapshotChanges();
  }

  getorgtime() {
    return this.firestore.collection('timer', ref => {
      return ref.where('machine', '==', 'org');
    }).snapshotChanges();
  }


  deleteRohlinge(data) {
    return this.firestore
      .collection('rohlinge')
      .doc(data.payload.doc.id)
      .delete();
  }

  deleteUsers(data) {
    return this.firestore
      .collection('users')
      .doc(data.payload.doc.id)
      .delete();
  }
  deletematerial(data) {
    return this.firestore
      .collection('dentalmaterial')
      .doc(data.payload.doc.id)
      .delete();
  }
  deleteUploads(data: UploadInterface) {
    return this.firestore
      .collection('files')
      .doc(data.fireBaseDocId)
      .delete();
  }

  deletebrands(data) {
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .delete();
  }

  deletecolors(data) {
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .delete();
  }

  deletetypes(data) {
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .delete();
  }

  updateOrder(data) {
    this.addComment(data);
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        archiv: false,
        status: 2,
        docID: data.payload.doc.id,
        docID2: data.payload.doc.id
      }, {
        merge: true
      });

  }

  setdocID(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        docID: data.payload.doc.id,
        docID2: data.payload.doc.id
      }, {
        merge: true
      });

  }


  setBlankStatus(data) {
    console.log(data);
    return this.firestore
      .collection('rohlinge')
      .doc(data.payload.doc.id)
      .set({
        blankStatus: false
      }, {
        merge: true
      });

  }

  setprintstate(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 1
      }, {
        merge: true
      });

  }
  setprintstate2(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 2
      }, {
        merge: true
      });

  }
  setprintstate3(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 3
      }, {
        merge: true
      });

  }
  setprintstate4(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 4
      }, {
        merge: true
      });

  }
  setprintstate5(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 5
      }, {
        merge: true
      });

  }
  setprintstatefinal(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        printstatus: 6,
        printing: false,
        printready: true
      }, {
        merge: true
      });

  }

  setnegativdate(data) {
    const dmy = data.payload.doc.data().deliverydate; // your input string
    const a = dmy.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 - (+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString();
    const m = month.toString();
    const y = year.toString();
    this.negativdate2 = (y + m + d);
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  setnegativdatefinal(data) {
    this.setnegativdate(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        negativdate: this.negativdate2
      }, {
        merge: true
      });

  }

  setreadystate(data) {
    let materialdata = false;
    if (data.payload.doc.data().materialtype === 'Zirkon') {
      materialdata = true;
    }
    console.log(materialdata);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        zircon: materialdata,
        blankmillingfinish: true,
        millingfinish: true,
        billnote: '',
        kamine: false,
        kamintechnican: 'keiner',
        kaminnumber: 0,
      }, {
        merge: true
      });

  }
  setreadystate2(data) {
    let materialdata = false;
    if (data.payload.doc.data().materialtype === 'Zirkon') {
      materialdata = true;
    }
    console.log(materialdata);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        zircon: materialdata,
        blankmillingfinish2: true,
        millingfinish2: true,
        billnote: '',
        kamine: false,
        kamintechnican: 'keiner',
        kaminnumber: 0,
      }, {
        merge: true
      });
  }

  sendBillingMail(data) {
    console.log('Email ist gesendet');
    const beschreibung = data.payload.doc.data().description;
    const werkstoff = data.payload.doc.data().materialbrand;
    const company = data.payload.doc.data().companyname;
    this.firestore.collection('mail').add({
      to: 'a.hamm@zt-sauerland.de',
      cc: 'v.hamm-zentrale@t-online.de',
      bcc: 'dwinkelmann@outlook.com',
      template: {
        name: 'BillMail',
        data: {
          company: company,
          werkstoff: werkstoff,
          beschreibung: beschreibung,
        }
      }
    });
  }

  // TODO: Setze richtige Status

  setMovebackStatus(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 2,
        millagain: true,
        workingmachineavaible: 1
      }, {
        merge: true
      });

  }
  setMovebackStatus2(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        laterwork: true,
        workingmachineavaible2: 1,
        millagain: true
      }, {
        merge: true
      });

  }

  SetfinalState(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 11
      }, {
        merge: true
      });

  }

  Zircontaken(data) {
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        zircontaken: true
      }, {
        merge: true
      });

  }

  Zirconfinish(data) {
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        zircon: false
      }, {
        merge: true
      });

  }

  SetfinalState2(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        blank1ready: true,
        millingfinish: false
      }, {
        merge: true
      });

  }

  SetfinalState3(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        blank2ready: true,
        millingfinish2: false
      }, {
        merge: true
      });

  }




  setLaterworkfalse(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        laterwork: false
      }, {
        merge: true
      });

  }

  prepareforMilling(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 4,
        workingholder: 'L',
        workingmachineavaible: 0,
        blankmillingfinish: false,
        millingfinish: false
      }, {
        merge: true
      });

  }

  SetORGTimer(data) {
    const sm = this.orgtimerform.get('millingtime').value;
    const a = sm.split(':');
    const stunden = 3600000 * (+a[0]);
    const minuten = 60000 * (+a[1]);
    const millingtime = (stunden + minuten);
    console.log(stunden);
    console.log(minuten);
    console.log(millingtime);
    const time = Date.now() + millingtime;
    const starttime = Date.now();
    const timetotal = time - starttime;
    return this.firestore
      .collection('timer')
      .doc('org')
      .set({
        time,
        firsttime: true,
        timetotal,
        starttime
      }, {
        merge: true
      });

  }

  Setmb1Timer(data) {
    const sm = this.mb1timerform.get('millingtime').value;
    const a = sm.split(':');
    const stunden = 3600000 * (+a[0]);
    const minuten = 60000 * (+a[1]);
    const millingtime = (stunden + minuten);
    console.log(stunden);
    console.log(minuten);
    console.log(millingtime);
    const time = Date.now() + millingtime;
    const starttime = Date.now();
    const timetotal = time - starttime;
    return this.firestore
      .collection('timer')
      .doc('mb1')
      .set({
        time,
        firsttime: true,
        timetotal,
        starttime
      }, {
        merge: true
      });

  }


  Setmb2Timer(data) {
    const sm = this.mb2timerform.get('millingtime').value;
    const a = sm.split(':');
    const stunden = 3600000 * (+a[0]);
    const minuten = 60000 * (+a[1]);
    const millingtime = (stunden + minuten);
    console.log(stunden);
    console.log(minuten);
    console.log(millingtime);
    const time = Date.now() + millingtime;
    const starttime = Date.now();
    const timetotal = time - starttime;
    return this.firestore
      .collection('timer')
      .doc('mb2')
      .set({
        time,
        firsttime: true,
        timetotal,
        starttime
      }, {
        merge: true
      });

  }

  prepareforMillingRight(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 4,
        workingholder: 'R',
        workingmachineavaible: 0,
        blankmillingfinish: false,
        millingfinish: false
      }, {
        merge: true
      });

  }

  prepareforMilling2(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        workingholder2: 'L',
        workingmachineavaible2: 0,
        blankmillingfinish2: false,
        millingfinish2: false
      }, {
        merge: true
      });

  }

  prepareforMilling2Right(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        workingholder2: 'R',
        workingmachineavaible2: 0,
        blankmillingfinish2: false,
        millingfinish2: false
      }, {
        merge: true
      });

  }

  updatebillingOrder(data) {
    console.log(data);
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        workingbill: false,
        billready: true,
      }, {
        merge: true
      });

  }

  addComment(data) {
    const extracomment = this.commentform.get('extracomment').value;
    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        comment: extracomment
      }, {
        merge: true
      });


  }

  updateworkingOrder() {
    if (this.workingform.get('laterwork').value === '') {
      this.laterwork2 = false;
    } else if (this.workingform.get('laterwork').value === false) {
      this.laterwork2 = false;
    } else {
      this.laterwork2 = true;
    }
  }


  updateworkingOrderfinal(data) {

    const workingblank = this.workingform.get('workingblank').value;
    const workingcharge = this.workingform.get('workingcharge').value;
    const workingshippingcost = this.workingform.get('workingshippingcost').value;
    const workingbill = this.workingform.get('workingbill').value;
    const workingtime = this.workingform.get('workingtime').value;
    const workingmachine = this.workingform.get('workingmachine').value;
    const workingblank2 = this.workingform.get('workingblank2').value;
    const workingcharge2 = this.workingform.get('workingcharge2').value;
    const workingtime2 = this.workingform.get('workingtime2').value;
    const workingmachine2 = this.workingform.get('workingmachine2').value;
    const laterwork = this.laterwork2;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 0;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 3,
        workingblank,
        workingcharge,
        workingbill,
        workingtime,
        workingmachine,
        workingshippingcost,
        workingcharge2,
        workingblank2,
        workingtime2,
        workingmachine2,
        workingmachineavaible,
        workingmachineavaible2,
        laterwork,
        blankmillingfinish,
        blankmillingfinish2
      }, {
        merge: true
      });

  }

  updateworkingOrder2(data) {
    const workingblank2 = this.secondworkingform.get('workingblank2').value;
    const workingcharge2 = this.secondworkingform.get('workingcharge2').value;
    const workingtime2 = this.secondworkingform.get('workingtime2').value;
    const workingmachine2 = this.secondworkingform.get('workingmachine2').value;
    const laterwork = false;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 1;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 3,
        workingcharge2,
        workingblank2,
        workingtime2,
        workingmachine2,
        workingmachineavaible,
        blankmillingfinish,
        blankmillingfinish2,
        workingmachineavaible2,
        laterwork
      }, {
        merge: true
      });

  }

  updateworkingOrder3(data) {
    const workingblank = this.workingform.get('workingblank').value;
    const workingcharge = this.workingform.get('workingcharge').value;
    const workingshippingcost = this.workingform.get('workingshippingcost').value;
    const workingbill = this.workingform.get('workingbill').value;
    const workingtime = this.workingform.get('workingtime').value;
    const workingmachine = this.workingform.get('workingmachine').value;
    const workingholder = this.workingform.get('workingholder').value;
    const workingblank2 = this.workingform.get('workingblank2').value;
    const workingcharge2 = this.workingform.get('workingcharge2').value;
    const workingtime2 = this.workingform.get('workingtime2').value;
    const workingmachine2 = this.workingform.get('workingmachine2').value;
    const workingholder2 = this.workingform.get('workingholder2').value;
    const laterwork = false;
    const workingmachineavaible = 1;
    const workingmachineavaible2 = 1;
    const blankmillingfinish = false;
    const blankmillingfinish2 = false;

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 3,
        workingblank,
        workingcharge,
        workingbill,
        workingtime,
        workingmachine,
        workingholder,
        workingshippingcost,
        workingcharge2,
        workingblank2,
        workingtime2,
        workingmachine2,
        workingholder2,
        workingmachineavaible,
        workingmachineavaible2,
        laterwork,
        blankmillingfinish,
        blankmillingfinish2
      }, {
        merge: true
      });

  }

  updateworkingOrderfinalback(data) {
    const laterwork = this.laterwork2;
    const workingmachineavaible = 0;
    const workingmachineavaible2 = 1;
    const blankmillingfinish = '';
    const blankmillingfinish2 = '';

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 2,
        workingmachineavaible,
        workingmachineavaible2,
        laterwork,
        blankmillingfinish,
        blankmillingfinish2
      }, {
        merge: true
      });

  }

  updateworkingOrder2back(data) {

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 2,
      }, {
        merge: true
      });

  }

  updateworkingOrder3back(data) {

    return this.firestore
      .collection('files')
      .doc(data.payload.doc.id)
      .set({
        status: 2,
        workingmachineavaible2: '',
      }, {
        merge: true
      });

  }


  movetoNew(data: UploadInterface) {
    return this.firestore
      .collection('files')
      .doc(data.fireBaseDocId)
      .set({
        status: 1
      }, {
        merge: true
      });

  }

  NewOrderCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 1);
    }).get();
  }

  NewIosCount() {
    return this.firestore.collection('iosScans').get();
  }

  KaminCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('kamine', '==', true);
    }).get();
  }

  ZirkonCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish', '==', true)
      .where('blankmillingfinish', '==', true).where('zircon', '==', true).where('materialtype', '==', 'Zirkon');
    }).get();
  }

  ZirkonCount2() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish2', '==', true)
      .where('blankmillingfinish2', '==', true).where('zircon', '==', true).where('materialtype', '==', 'Zirkon');
    }).get();
  }

  WorkOrderCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 2);
    }).get();
  }

  WorkOrderCount2() {
    return this.firestore.collection('files', ref => {
      return ref.where('laterwork', '==', true);
    }).get();
  }

  ShippingCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish', '==', true)
        .where('blankmillingfinish', '==', true).where('zircon', '==', false);
    }).get();
  }

  ShippingCount2() {
    return this.firestore.collection('files', ref => {
      return ref.where('millingfinish2', '==', true)
        .where('blankmillingfinish2', '==', true).where('zircon', '==', false);
    }).get();
  }

  PrepareMillingCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '==', 3);
    }).get();
  }

  PrepareMillingCount2() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingmachineavaible2', '==', 1);
    }).get();
  }

  BillingCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('workingbill', '==', true).where('laterwork', '==', false).where('millingfinish', '==', true);
    }).get();
  }

  PrintingCount() {
    return this.firestore.collection('files', ref => {
      return ref.where('status', '>', 1).where('printing', '==', true);
    }).get();
  }



}
