<div class="clr-row">
        <div class="clr-col-1">
        </div>
        <div class="clr-col-10">
          <h1>Rechnungen: <strong>{{size}} </strong></h1><a (click)="toggleDisplay()" class="btn btn-sm"><clr-icon  shape="download"></clr-icon>Mehr Infos</a>
          <div class="clr-row">
              <div *ngFor="let order of files" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                  <div class="card">
                      <div class="card-header">
                            <div *ngIf="!order.payload.doc.data().billnote == ''" class="alert alert-warning alert-sm"
                            role="alert">
                            <div class="alert-items">
                                <div class="alert-item static">
                                    <div class="alert-icon-wrapper">
                                        <clr-icon class="alert-icon" shape="note"></clr-icon>
                                    </div>
                                    <div class="alert-text">
                                        <span> {{ order.payload.doc.data().billnote }}</span>
                                    </div>
                                </div>
                            </div>
                            <br>
                        </div>
                          <div [hidden]="!order.payload.doc.data().workingshippingcost" class="alert alert-warning alert-sm" role="alert">
                              <div class="alert-items">
                                  <div class="alert-item static">
                                      <div class="alert-icon-wrapper">
                                          <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                                      </div>
                                      <div class="alert-text">
                                          Bei dieser Arbeit Versand berechnen!
                                      </div>
                                  </div>
                              </div>
                              <br>
                          </div>
                          <strong>{{order.payload.doc.data().companyname}}  </strong><a (click)="DisplayAdressModal(order)"><clr-icon  shape="home"></clr-icon></a>
                          <div *ngIf="order.payload.doc.data().docID == ModalID">
                            <clr-modal [(clrModalOpen)]="showadress">
                                    <h3 class="modal-title">Adresse</h3>
                                    <div *ngFor="let order of companyadress" class="modal-body">
                                      <h3>Adresse:</h3>
                                      <p><strong>{{order.payload.doc.data().companytype}}</strong><br>
                                      <span>{{order.payload.doc.data().technican}}</span><br>
                                      {{order.payload.doc.data().companyname}}<br>
                                      {{order.payload.doc.data().street}}<br>
                                      {{order.payload.doc.data().postcode}} {{order.payload.doc.data().city}}</p>
                                    </div>
                                    <div class="modal-footer">
                                        <button type="button" class="btn btn-primary" (click)="showadress = !showadress">OK</button>
                                    </div>
                                  </clr-modal>
                                </div>
                      </div>
                      <div class="card-block">
                          <div class="card-title">
                                {{order.payload.doc.data().description}}
                                
                                <button (click)="copyMessage(order.payload.doc.data().description)" class="btn btn-info btn-sm">kopieren</button>
                          </div>
                          <hr>
                          <div class="card-text">
                              <a class="label label-orange">
                                  Einheiten
                                  <span class="badge">{{ order.payload.doc.data().ordernumber }}</span>
                              </a>
                              <br>
                              <strong>Patientenname:</strong><br>
                              <a (click)="copyMessage(order.payload.doc.data().patlastname+', '+order.payload.doc.data().patname)" class="label label-light-blue clickable">{{ order.payload.doc.data().patlastname }}, {{ order.payload.doc.data().patname }}</a>
                              <br>
                              <strong>Rohling und Charge:</strong><br>
                              <span>{{ order.payload.doc.data().materialtype }} {{ order.payload.doc.data().materialbrand }} {{ order.payload.doc.data().teethcolor }} </span>
                              <br>
                              <a (click)="copyMessage(order.payload.doc.data().workingcharge)" class="label label-light-blue clickable">{{ order.payload.doc.data().workingcharge }} l {{ order.payload.doc.data().workingblank }}</a>
                              <br>
                              <div [hidden]="!order.payload.doc.data().workingblank2">
                                  <strong>Rohling und Charge2:</strong><br>
                                  <span>{{ order.payload.doc.data().materialtype }} {{ order.payload.doc.data().materialbrand }} {{ order.payload.doc.data().teethcolor }} </span>
                                  <br>
                                  <a (click)="copyMessage(order.payload.doc.data().workingcharge2)" class="label label-light-blue clickable">{{ order.payload.doc.data().workingcharge2 }} l {{ order.payload.doc.data().workingblank2 }}</a>
                                  <br>
                            </div>
                      </div>
                    </div>
                      <button (click)="DisplayModal1(order)" class="btn btn-primary btn-block">Rechnung geschrieben</button>
                      <div *ngIf="order.payload.doc.data().docID == ModalID">
                      <clr-modal [(clrModalOpen)]="isOpen">
                              <h3 class="modal-title">Rechnung geschrieben</h3>
                              <div class="modal-body">
                                <p>Ist die Rechnung fertig geschrieben und vom Abteilungsleiter Elton abgesegnet?</p>
                              </div>
                              <div class="modal-footer">
                                  <button (click)="isOpen = !isOpen" type="button" class="btn btn-outline">Nein noch nicht :-(</button>
                                  <button type="button" class="btn btn-primary" (click)="DisplayModal2(order)">Ist geschrieben :-)</button>
                              </div>
                            </clr-modal>
                        </div>
                        <div *ngIf="order.payload.doc.data().docID == ModalID">
                            <clr-modal [(clrModalOpen)]="isOpen3">
                                    <h3 class="modal-title">Elton betritt mit großen Schritten die Anmeldung!</h3>
                                    <div class="modal-body">
                                      <p>"Ich will noch mal sicher gehen ob wirklich alles stimmt???"</p>
                                      <div [hidden]="!order.payload.doc.data().workingshippingcost" class="alert alert-warning alert-sm" role="alert">
                                        <div class="alert-items">
                                            <div class="alert-item static">
                                                <div class="alert-icon-wrapper">
                                                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                                                </div>
                                                <div class="alert-text">
                                                    Elton merkt an, das bei dieser Arbeit Versand berechnet wird!
                                                </div>
                                            </div>
                                        </div>
                                        <br>
                                    </div>  
                                    </div>
                                

                                    <div class="modal-footer">
                                        <button (click)="toggleDisplay2()" type="button" class="btn btn-outline">Elton hat Recht :-(</button>
                                        <button type="button" class="btn btn-primary" (click)="Submit(order)">Ja Meister es stimmt alles</button>
                                    </div>
                                  </clr-modal>
                                </div>
                  </div>
              </div>
          </div>
        </div>