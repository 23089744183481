import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { KundenListComponent } from './kunden/kunden-list/kunden-list.component';
import { AddblankComponent } from './rohlinge/addblank/addblank.component';
import { UploadListComponent } from './uploads/upload-list/upload-list.component';
import { AddwerkstoffComponent } from './werkstoff/addwerkstoff/addwerkstoff.component';
import { MaschinenHomeComponent } from './maschinen/maschinen-home/maschinen-home.component';
import { MaschinenMB2Component } from './maschinen/maschinen-mb2/maschinen-mb2.component';
import { MaschinenOrganicalComponent } from './maschinen/maschinen-organical/maschinen-organical.component';
import { MaschinenMB1Component } from './maschinen/maschinen-mb1/maschinen-mb1.component';
import { WorkingorderComponent } from './workingorder/workingorder.component';
import { PrintingComponent } from './printing/printing.component';
import { BillingComponent } from './billing/billing.component';
import { PremillingComponent } from './premilling/premilling.component';
import { ShippingComponent } from './shipping/shipping.component';
import { OverviewComponent } from './overview/overview.component';
import { ZirconComponent } from './zircon/zircon.component';
import { TechnicansComponent } from './technicans/technicans.component';
import { KamineComponent } from './kamine/kamine.component';
import { NewworkingorderComponent } from './newworkingorder/newworkingorder.component';
import { BarcodeComponent } from './barcode/barcode.component';
import { StatisticsComponent } from './statistics/statistics.component';
import { TestdanielComponent } from './testdaniel/testdaniel.component';
import { IosscansComponent } from "./iosscans/iosscans.component";

const routes: Routes = [
  {path: 'working' , component: NewworkingorderComponent},
  {path: 'statistik' , component: StatisticsComponent},
  {path: 'rohlinge' , component: AddblankComponent},
  {path: 'kunden' , component: KundenListComponent},
  {path: 'uploads' , component: UploadListComponent},
  {path: 'werkstoff' , component: AddwerkstoffComponent},
  {path: 'maschinenhome' , component: MaschinenHomeComponent},
  {path: 'organical' , component: MaschinenOrganicalComponent},
  {path: 'newworking' , component: BarcodeComponent},
  {path: 'printing' , component: PrintingComponent},
  {path: 'rechnungen' , component: BillingComponent},
  {path: 'vorbereitung' , component: PremillingComponent},
  {path: 'versand' , component: ShippingComponent},
  {path: 'mb1' , component: MaschinenMB1Component},
  {path: 'mb2' , component: MaschinenMB2Component},
  {path: 'Ubersicht' , component: OverviewComponent},
  {path: 'Zircon' , component: ZirconComponent},
  {path: 'kamine' , component: KamineComponent},
  {path: 'technicans' , component: TechnicansComponent},
  {path: 'daniel' , component: TestdanielComponent},
  {path: 'ios' , component: IosscansComponent},

  {path: '404', component: UploadListComponent},
  {path: '**', redirectTo: '/404'}


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
