import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-barcode',
  templateUrl: './barcode.component.html',
  styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit {

  constructor() { 

  }
  datum = Date.now()

  barcode: string;

  ngOnInit() {
  }

}
