<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>Kamine: <strong> {{ numberfiles }} </strong></h1>
        <div class="clr-row">
            <div *ngFor="let order of kamindatalive" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div class="card">
                    <div class="card-header">
                        {{ order.payload.doc.data().companyname }}
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{ order.payload.doc.data().description }}
                        </div>
                        <div class="card-text">
                            <a class="label label-orange">
                                Einheiten
                                <span class="badge">{{ order.payload.doc.data().kaminnumber }}</span>
                            </a>
                            <strong>Patientenname:</strong><br>
                            <a (click)="copyMessage(order.payload.doc.data().patlastname+', '+order.payload.doc.data().patname)"
                                class="label label-light-blue clickable">{{ order.payload.doc.data().patlastname }},
                                {{ order.payload.doc.data().patname }}</a>
                            <br>
                            <strong>Auftragsdatum:</strong><br>
                            <a (click)="copyMessage(order.payload.doc.data().date)"
                                class="label label-light-blue clickable">{{ order.payload.doc.data().date | date }}</a>
                            <br>
                            <strong>Techniker:</strong><br>
                            <a (click)="copyMessage(order.payload.doc.data().kamintechnican)"
                                class="label label-light-blue clickable">{{ order.payload.doc.data().kamintechnican }}</a>
                            <br>

                        </div>
                    </div>
                    <div class="card-footer">
                        <button (click)="OpenDetailpage(order)" class="btn btn-sm btn-link">Kamine berechnet</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div *ngIf="detailpage">
        <clr-modal [(clrModalOpen)]="detailpagemodal" [clrModalSize]="'sm'">
            <h3 class="modal-title">Kamine berechnet?</h3>
            <div class="modal-body">
                <strong>Patientenname:</strong><br>
                <a (click)="copyMessage(detailpage.patlastname+', '+detailpage.patname)"
                    class="label label-light-blue clickable">{{ detailpage.patlastname }},
                    {{ detailpage.patname }}</a>
                <br>
                <br>
                <strong>Auftragsdatum:</strong><br>
                <a (click)="copyMessage(detailpage.date)"
                    class="label label-light-blue clickable">{{ detailpage.date | date }}</a>
                <br>
                <br>
                <strong>Techniker:</strong><br>
                <a (click)="copyMessage(detailpage.kamintechnican)"
                    class="label label-light-blue clickable">{{ detailpage.kamintechnican }}</a>
                <br>
                <br>
                <strong>Einheiten:</strong><br>
                <h5>{{ detailpage.kaminnumber }}</h5>
                <br>
                <br>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline" (click)="detailpagemodal = false">Abbrechen</button>
                <button type="button" class="btn btn-primary" (click)="setDone()">Ok</button>
            </div>
        </clr-modal>
    </div>