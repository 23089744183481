import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseService } from 'src/app/shared/firebase.service';

@Component({
  selector: 'app-maschinen-mb2',
  templateUrl: './maschinen-mb2.component.html',
  styleUrls: ['./maschinen-mb2.component.scss']
})
export class MaschinenMB2Component implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    ) { }

    HolderLeft;
    HolderLeft2;
    HolderRight;
    HolderRight2;

    HolderLeftBlank;
    HolderRightBlank;
    HolderLeftBlank2;
    HolderRightBlank2;

    millingtime;

    dateNow;
    timeleft;
    timedone;

  ngOnInit() {
    this.getHolderLeft();
    this.getHolderLeft2();
    this.getHolderRight();
    this.getHolderRight2();

    this.getHolderLeftBlank();
    this.getHolderRightBlank();
    this.getHolderLeftBlank2();
    this.getHolderRightBlank2();

    this.getTimenew();
    this.updatetime();
    this.setDatenow();
  }

  getTimenew = () =>
  this.firebaseService.getmb2time().subscribe(res => (this.millingtime = res))

  getHolderLeft = () =>
  this.firebaseService.getMachineUploadsDCTleft().subscribe(res => (this.HolderLeft = res))

  getHolderLeft2 = () =>
  this.firebaseService.getMachineUploadsDCTleft2().subscribe(res => (this.HolderLeft2 = res))

  getHolderRight = () =>
  this.firebaseService.getMachineUploadsDCTright().subscribe(res => (this.HolderRight = res))

  getHolderRight2 = () =>
  this.firebaseService.getMachineUploadsDCTright2().subscribe(res => (this.HolderRight2 = res))

  getHolderLeftBlank = () =>
  this.firebaseService.getMachineUploadsDCTleftBlank().subscribe(res => (this.HolderLeftBlank = res))

  getHolderRightBlank = () =>
  this.firebaseService.getMachineUploadsDCTrightBlank().subscribe(res => (this.HolderRightBlank = res))

  getHolderLeftBlank2 = () =>
  this.firebaseService.getMachineUploadsDCTleftBlank2().subscribe(res => (this.HolderLeftBlank2 = res))

  getHolderRightBlank2 = () =>
  this.firebaseService.getMachineUploadsDCTrightBlank2().subscribe(res => (this.HolderRightBlank2 = res))

  setreadystate = data =>
  this.firebaseService.setreadystate(data)

  setreadystate2 = data =>
  this.firebaseService.setreadystate2(data)

  Submit(data) {
  this.setreadystate(data);
  }
  Submit2(data) {
  this.setreadystate2(data);
  }
  moveBack(data) {
    this.setmoveback(data);
  }

  moveBack2(data) {
    this.setmoveback2(data); 
  }

  setmoveback = data =>
  this.firebaseService.setMovebackStatus(data)

  setmoveback2 = data =>
  this.firebaseService.setMovebackStatus2(data)

  setTime(data) {
    this.timesetter(data);
  }

  timesetter = data =>
  this.firebaseService.Setmb2Timer(data)

  resttime (data) {
    this.timeleft = Date.now() - data.payload.doc.data().starttime;
  }


  updatetime() {
    this.dateNow = setInterval(() => { this.setDatenow(); }, 4000);
    
  }

  setDatenow () {
    this.dateNow = Date.now();
  }
}

