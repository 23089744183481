import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable } from 'rxjs';
import { DatePipe } from '@angular/common';

interface Materialandcolor {
  material: string;
}

@Component({
  selector: 'app-workingorder',
  templateUrl: './workingorder.component.html',
  styleUrls: ['./workingorder.component.scss']
})
export class WorkingorderComponent implements OnInit {


  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    public datepipe: DatePipe,
    ) { }

  isOpen = false;
  isOpen2 = false;
  isOpen4 = false;
  showInfo = true;
  modal;
  ModalID;
  ModalID2;
  showsecondblank = true;
  showsecondblanklater = false;
  submitbutton = false;
  files;
  files2;
  size;
  dentalmaterial;
  dentalrohling;
  dentalrohling2;
  dentalrohlingcharge;
  dentalrohlingcharge2;
  dentalrohlingcharge3;
  showQR;
  laterwork: boolean;
  date;
  datePlus1;
  latest_date: string;
  deletemodal: boolean = true;

  keyword = 'blankName';
  data2;

  public options: string;
  public options2: string;

  specialmaterialtypeCollection: AngularFirestoreCollection<Materialandcolor>;
  specialmateriatype: Observable<Materialandcolor[]>;
  materialbrandCollection: AngularFirestoreCollection<Materialandcolor>;
  materialbrand: Observable<Materialandcolor[]>;

  ngOnInit() {
    this.getUploads();
    this.getUploads2();
    this.getTotalNumber();
    this.firebaseService.NewOrderCount();
    this.setdate();
    this.getAutocompletedata();
  }

  getAutocompletedata() {
    this.materialbrandCollection = this.afs.collection('rohlinge');
    this.materialbrand = this.materialbrandCollection.valueChanges();
    console.log(this.materialbrand._isScalar.valueOf);
  }

  getColor(data) { (2)
    switch (data) {
      case 'dima Mill CoCr':
        return '#10de2e';
      case 'ArgenP Clear':
        return '#ff6ea3';
      case 'priti Translucent':
        return '#07f6fa';
      case 'priti Opaque':
        return '#f79e05';
      case 'ArgenW blue':
        return '#fafa07';
      case 'Medentika Preface':
        return '#f70505';
        case 'Whitepeaks Copra Ti-4':
          return '#f70505';
    }
  }

  setdate() {
    this.date = new Date();
    this.latest_date = this.datepipe.transform(this.date, 'dd.MM.yyyy');
    console.log(this.latest_date);
  }

  deleteUploads = data => this.firebaseService.deleteUploads(data);

  getUploads = () =>
  this.firebaseService.getworkingUploads().subscribe(res => (this.files = res))

  getUploads2 = () =>
  this.firebaseService.getworkingUploads2().subscribe(res => (this.files2 = res))

  getTotalNumber = () =>
  this.firebaseService.WorkOrderCount().subscribe(snap => {
    this.size = snap.size;
    console.log(this.size);
  })

  getDentalmaterial() {
    this.firebaseService
    .getDentalmaterial()
    .subscribe(res => (this.dentalmaterial = res));
   }

   getDentalrohling() {
    this.firebaseService
    .getRohlinge()
    .subscribe(res => (this.dentalrohling = res));
   }

   getMaterialRohlinge(data) {
    this.firebaseService
    .getMaterialRohlinge(data)
    .subscribe(res => (this.dentalrohling = res));
   }

   getDentalrohlingcharge() {
    this.firebaseService
    .getChargenumber()
    .subscribe(res => (this.dentalrohlingcharge = res));
   }

   getDentalrohlingcharge2() {
    this.firebaseService
    .getChargenumber2()
    .subscribe(res => (this.dentalrohlingcharge2 = res));
   }

   getDentalrohlingcharge3() {
    this.firebaseService
    .getChargenumber3()
    .subscribe(res => (this.dentalrohlingcharge3 = res));
   }

   getSpecialBlank() {

    const specialmaterialtype = this.files.materialtype.value;

    this.specialmaterialtypeCollection = this.afs.collection('rohlinge', ref => {
      return ref.where('material', '==', specialmaterialtype);
    });
    this.specialmateriatype = this.specialmaterialtypeCollection.valueChanges();
  }

  Submit(data) {
    this.setlaterwork();
    this.markCompleted(data);
    this.isOpen = false;
  }

  Submit3(data) {
    this.markCompleted2(data);
    this.isOpen4 = false;
  }

  Submit4(data) {
    this.markCompleted3(data);
    this.isOpen4 = false;
  }

  SubmitDelete(data) {
    this.deleteUploads(data);
    this.isOpen2 = false;
  }


  markCompleted = data =>
    this.firebaseService.updateworkingOrderfinal(data)

    setlaterwork() {
      this.firebaseService.updateworkingOrder();
    }

    markCompleted2 = data =>
    this.firebaseService.updateworkingOrder2(data)

    // laterwork on false
    markCompleted3 = data =>
    this.firebaseService.updateworkingOrder3(data)



    moveBack = data =>
    this.firebaseService.movetoNew(data)

    toggleDisplay() {
      this.showInfo = !this.showInfo;
    }
    toggleDisplay2() {
      this.showsecondblank = !this.showsecondblank;
      this.showsecondblanklater = !this.showsecondblanklater;
      this.submitbutton = !this.submitbutton;
    }

    DisplayModal1(data) {
      this.getMaterialRohlinge(data);
      this.MatchDocID(data);
      this.isOpen = true;
    }
    DisplayModal2(data) {
      this.getMaterialRohlinge(data);
      this.MatchDocID(data);
      this.isOpen2 = true;
    }
    DisplayModal4(data) {
      this.getMaterialRohlinge(data);
      this.MatchDocID(data);
      this.isOpen4 = true;
    }

    MatchDocID(data) {
      this.ModalID = data.payload.doc.id;
    }

    MatchDocID2(data) {
      this.ModalID2 = data.payload.doc.id;
    }

    getOptions() {
      this.getDentalrohlingcharge();
    }
    getOptions2() {
      this.getDentalrohlingcharge2();
    }
    getOptions3() {
      this.getDentalrohlingcharge3();
    }

}
