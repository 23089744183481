<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>Sinterofen:</h1>
        <div class="clr-row">
            <div *ngFor="let order of files" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div [ngStyle]="{'background-color':getColor(order.payload.doc.data().zircontaken)}" class="card">
                    <div class="card-header">
                        <strong>{{order.payload.doc.data().companyname}}</strong>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{order.payload.doc.data().description}} <span
                                class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                        </div>
                        <hr>
                        <div class="card-text">
                            <strong>Techniker: {{order.payload.doc.data().technican}}</strong>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Werkstoff
                                            <br>
                                            <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                                        <td>Farbe
                                            <br>
                                            <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button (click)="Zircontaken(order)" class="btn btn-primary btn-block">Arbeit entnommen</button>
                    <button (click)="Zirconfinish(order)" class="btn btn-primary btn-block">Arbeit entfernen</button>
                </div>
            </div>
            <div *ngFor="let order of files2" class="clr-col-lg-2 clr-col-md-6 clr-col-12">
                <div [ngStyle]="{'background-color':getColor(order.payload.doc.data().zircontaken)}" class="card">
                    <div class="card-header">
                        <strong>{{order.payload.doc.data().companyname}} <span class="label label-purple">Teil
                                2</span></strong>
                    </div>
                    <div class="card-block">
                        <div class="card-title">
                            {{order.payload.doc.data().description}} <span
                                class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                        </div>
                        <hr>
                        <div class="card-text">
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td>Rohling
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingblank2 }}</strong>
                                        </td>
                                        <td>Maschine
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingmachine2 }}</strong>
                                    </tr>
                                    <tr>
                                        <td>Werkstoff
                                            <br>
                                            <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                                        <td>Farbe
                                            <br>
                                            <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                                    </tr>
                                    <tr>
                                        <td>Zeit
                                            <br>
                                            <strong>{{ order.payload.doc.data().workingtime2 }}</strong>
                                        <td>Lieferdatum
                                            <br>
                                            <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <button (click)="Zircontaken(order)" class="btn btn-primary btn-block">Arbeit entnommen</button>
                    <button (click)="Zirconfinish(order)" class="btn btn-primary btn-block">Arbeit entfernen</button>
                </div>
            </div>
        </div>
    </div>