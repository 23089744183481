<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-6">
            <h3>Techniker</h3>
            <form clrForm [formGroup]="this.firebaseService.technicansform">
                    <clr-input-container>
                    <label>Vorname</label>
            <input clrInput placeholder="Vorname" formControlName="tecname" required/>
            </clr-input-container>
            <clr-input-container>
            <label>Nachname</label>
            <input clrInput placeholder="Nachname" formControlName="teclastname" required/>
            </clr-input-container>
            <clr-input-container>
                    <label>Technikernummer</label>
                    <input clrInput placeholder="Technikernummer" formControlName="tecnumber" required/>
                    </clr-input-container>
            <br>
            <button (click)="onSubmitTechnicans()" class="btn btn-primary">Anlegen</button>
            </form>
        </div>
</div>
    <div class="clr-row">
                <div class="clr-col-1"></div>
                <div class="clr-col-3">
                                <clr-datagrid>
                                    <clr-dg-column>Vorname</clr-dg-column>
                                    <clr-dg-column>Nachname</clr-dg-column>
                                    <clr-dg-column>Technikernummer</clr-dg-column>
                                
                                    <clr-dg-row *ngFor="let order of technicansdata">
                                      <clr-dg-cell>{{order.payload.doc.data().tecname}}</clr-dg-cell>
                                      <clr-dg-cell>{{order.payload.doc.data().teclastname}}</clr-dg-cell>
                                      <clr-dg-cell><a (click)="OpenDetailpage(order)" class="label label-orange clickable"> {{order.payload.doc.data().tecnumber}} </a></clr-dg-cell>
                                    </clr-dg-row>
                                </clr-datagrid>
                        </div>
        </div>
    <div *ngIf="detailpage">
    <clr-modal [(clrModalOpen)]="detailpagemodal">
                <h3 class="modal-title">Techniker</h3>
                <div class="modal-body">
                                <form clrForm>
                                                <span class="clr-sr-only">Tabbing through form inputs will trigger validation messages to be read aloud.</span>
                                                <clr-input-container>
                                                    <label>Vorname</label>
                                                    <input clrInput type="text"  [(ngModel)]="tecname" name="Vorname"/>
                                                </clr-input-container>
                                                <clr-input-container>
                                                    <label>Nachname</label>
                                                    <input clrInput type="text"  [(ngModel)]="teclastname" name="Lastname"/>
                                                </clr-input-container>
                                                <clr-input-container>
                                                    <label>TK-nummer</label>
                                                    <input clrInput type="number"  [(ngModel)]="tecnumber" name="lastName"/>
                                                </clr-input-container>
                                            </form>
                </div>
                <div class="modal-footer">
                    <button (click)="detailpagemodal = !detailpagemodal" type="button" class="btn btn-outline">Zurück</button>
                    <button (click)="setTechnican()" type="button" class="btn btn-primary">Ändern</button>
                </div>
        </clr-modal>
</div>
