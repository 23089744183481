<div class="main-container">
            <div class="clr-row">
                    <div class="clr-col-sm-12 clr-col-lg-4">
                            <app-maschinen-mb1></app-maschinen-mb1>
                    </div>
                    <div class="clr-col-sm-12 clr-col-lg-4">
                            <app-maschinen-mb2></app-maschinen-mb2>
                    </div>
                    <div class="clr-col-sm-12 clr-col-lg-4">
                            <app-maschinen-organical></app-maschinen-organical>
                    </div>
                </div>
</div>