<div class="clr-row">
<div class="clr-col-4">
<h3>Material</h3>
<form clrForm [formGroup]="this.firebaseService.materialtypeform">
        <clr-input-container>
        <label>Name</label>
<input clrInput placeholder="Materialtyp" formControlName="name" required/>
</clr-input-container>
<clr-input-container>
<label>Schlüssel</label>
<input clrInput placeholder="Materialschlüssel" formControlName="typekey" required/>
</clr-input-container>
<br>
<button (click)="onSubmitMaterialtype()" class="btn btn-primary">Anlegen</button>
</form>
<clr-datagrid>
    <clr-dg-column>Materialtyp</clr-dg-column>
    <clr-dg-column>Materialschlüssel</clr-dg-column>

    <clr-dg-row *ngFor="let order of dentalmaterial">
      <clr-dg-cell>{{order.payload.doc.data().name}}</clr-dg-cell>
      <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().typekey}}</span></clr-dg-cell>
      <clr-dg-cell><a (click)="deletetypes(order)" class="label clickable"><clr-icon  shape="trash"></clr-icon>Löschen</a></clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
</div>
<div class="clr-col-4">
<h3>Materialmarken</h3>
<form clrForm [formGroup]="this.firebaseService.materialbrandform">
        <clr-input-container>
        <label>Marke</label>
<input clrInput placeholder="Markenname" formControlName="brandname" required/>
</clr-input-container>
<clr-select-container>
        <label>Schlüssel</label>
        <select clrSelect formControlName="typebrandkey">
          <option *ngFor="let order of dentalmaterial" value="{{order.payload.doc.data().typekey}}">
            {{order.payload.doc.data().name}}</option>
        </select>
      </clr-select-container>
<br>
<button (click)="onSubmitMaterialbrand()" class="btn btn-primary">Anlegen</button>
</form>
<clr-datagrid>
    <clr-dg-column>Materialmarke</clr-dg-column>
    <clr-dg-column>Materialschlüssel</clr-dg-column>
  <clr-dg-column>Farb-Kennzeichnung</clr-dg-column>

    <clr-dg-row *ngFor="let order of dentalmaterialbrand">
      <clr-dg-cell>{{order.payload.doc.data().brandname}}</clr-dg-cell>
      <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().typebrandkey}}</span></clr-dg-cell>
      <clr-dg-cell>
        <input
          [(colorPicker)]="color"
          [cpPosition]="'left'"
          [cpOKButton]="true"
          [cpCancelButton]="true"
          (colorPickerSelect)="onColorSelected($event,order.payload.doc.id)"
          [style.background]="order.payload.doc.data().color"
          (colorPickerOpen)="setColor(order.payload.doc.data().color)"
        /></clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
</div>
<div class="clr-col-3">
<h3>Materialfarben</h3>
<form clrForm [formGroup]="this.firebaseService.colorsform">
        <clr-input-container>
        <label>Name</label>
<input clrInput placeholder="Farbe" formControlName="color" required/>
</clr-input-container>
<br>
<button (click)="onSubmitColor()" class="btn btn-primary">Hinzufügen</button>
</form>
<form clrForm [formGroup]="this.firebaseService.materialcolorsform">
        <button class="waves-effect waves-light btn col s4"
        *ngFor="let order of dentalcolors"
        (click)="addColor(order.payload.doc.data().color)">
        {{ order.payload.doc.data().color }}
        </button>
        <ul>
        <li *ngFor="let color of materialcolors">
                <span class="label label-orange">{{ color }}</span>
        <button (click)="removeColor(color)" class="btn btn-sm btn-link">Löschen</button>
        </li>
        </ul>
<clr-select-container>
        <label>Marke</label>
        <select clrSelect formControlName="brand">
          <option *ngFor="let order of dentalmaterialbrand" value="{{order.payload.doc.data().brandname}}">
            {{order.payload.doc.data().brandname}}</option>
        </select>
      </clr-select-container>
<br>
<button (click)="onSubmitMaterialcolor()" class="btn btn-primary">Anlegen</button>
</form>
<clr-datagrid>
    <clr-dg-column>Materialmarke</clr-dg-column>
    <clr-dg-column>Verfügbare Farben</clr-dg-column>

    <clr-dg-row *ngFor="let order of dentalmaterialcolor">
      <clr-dg-cell>{{order.payload.doc.data().brand}}</clr-dg-cell>
      <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().color}}</span></clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
</div>
</div>
