<clr-main-container>
    <div class="clr-row">
        <div class="clr-col-1">
        </div>
        <div class="clr-col-11">
          <h1>Rohlinge:</h1>
        </div>
        <div class="clr-col-1">
        </div>
        <div class="clr-col-4">

          <form clrForm [formGroup]="this.firebaseService.blankform">
            <clr-input-container>
              <label (click)="firebaseService.copyMessage( firebaseService.blankform.value.blankName )" >Name</label>
              <input  clrInput placeholder="Bezeichnung" formControlName="blankName" required/>

            </clr-input-container>
            <clr-input-container>
              <label (click)="firebaseService.copyMessage(firebaseService.blankform.value.cargeNumber)" >Carge</label>
              <input clrInput placeholder="Chargennummer" formControlName="cargeNumber" required/>


            </clr-input-container>
            <clr-select-container>
              <label>Material</label>
              <select clrSelect formControlName="blankmaterial">
                <option *ngFor="let order of dentalmaterial" value="{{order.payload.doc.data().typekey}}">
                  {{order.payload.doc.data().name}}</option>
              </select>
            </clr-select-container>
            <br>
            <button (click)="onSubmit()" class="btn btn-primary">Anlegen</button>
          </form>
        </div>
        <div class="clr-col-6">
          <app-blank-list></app-blank-list>
        </div>
        <div class="clr-col-1">
        </div>
      </div>
</clr-main-container>
