<div class="clr-row">
  <div class="clr-col-11">
    <clr-datagrid [(clrDgSelected)]="selected">
      <clr-dg-column>Kunde</clr-dg-column>
      <clr-dg-column>Patient</clr-dg-column>
      <clr-dg-column>Erstellungsdatum</clr-dg-column>
      <clr-dg-column>Downloads</clr-dg-column>
      <clr-dg-column *ngIf="checkDate(uploads)" >Archivieren</clr-dg-column>
      <clr-dg-column>Unvollständig</clr-dg-column>
      <clr-dg-row *clrDgItems="let upload of uploads" [clrDgItem]="upload">
        <clr-dg-cell>{{upload.dentist}}</clr-dg-cell>
        <clr-dg-cell>{{upload.patientName}} {{upload.patientLastname}}</clr-dg-cell>
        <clr-dg-cell>{{upload.onCreate | date:'medium'}}</clr-dg-cell>
        <clr-dg-cell *ngIf="!checkArray(upload)"><span class="label label-info">Neue Downloads verfügbar!</span>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="checkArray(upload)"><span class="label label-success">Alles heruntergeladen</span>
        </clr-dg-cell>
        <clr-dg-cell *ngIf="checkDate(uploads)">
          <button [ngClass]="(upload.status == OrderStatus.ARCHIVE) ? 'btn btn-primary btn-sm' : 'btn btn-sm'" (click)="setPatientStatus(upload, OrderStatus.ARCHIVE)">Archivieren</button>
        </clr-dg-cell>
        <clr-dg-cell>
          <button *ngIf="upload.status != OrderStatus.PENDING" (click)="setPatientStatus(upload, OrderStatus.PENDING)" type="button" class="btn btn-icon btn-info btn-sm " aria-label="done">
            <clr-icon shape="help"></clr-icon>
            Unvollständig
          </button>
          <button *ngIf="upload.status == OrderStatus.PENDING" (click)="setPatientStatus(upload, OrderStatus.NEW)" type="button" class="btn btn-warning btn-info btn-sm " aria-label="done">
            <clr-icon shape="warning"></clr-icon>
            Unvollständig !
          </button>
        </clr-dg-cell>

      </clr-dg-row>

      <clr-dg-detail *clrIfDetail="let detail">
        <clr-dg-detail-header>{{detail.dentist}} - {{detail.patientName}} {{detail.patientLastname}}
          <table class="table">
            <thead>
            <tr>
              <th>Art</th>
              <th>Upload-Datum</th>
              <th>Anmerkung</th>
              <th>Labor-Anmerkung</th>
              <th>Datei-Name</th>
              <th>Download</th>

            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of detail.uploads">
              <td>{{item['uploadType']}}</td>
              <td>{{item['onCreate'] | date:'medium'  }}</td>
              <td>
                <a *ngIf="(item['dentistNotes'] != '')" href="..." role="tooltip" aria-haspopup="true"
                   class="tooltip tooltip-right">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content">{{item['dentistNotes']}}</span>
                </a>
              </td>
              <td>
                <a *ngIf="(item['labNotes'] != null)" (click)="openEditModal(detail,item)" role="tooltip" aria-haspopup="true"
                   class="tooltip tooltip-right">
                  <clr-icon shape="info-circle" size="24"></clr-icon>
                  <span class="tooltip-content">{{item['labNotes']}}</span>
                </a>
                <a *ngIf="(item['labNotes'] == null)" (click)="openModal(detail,item)" role="tooltip" aria-haspopup="true"
                   class="tooltip tooltip-right">
                  <clr-icon shape="pencil" size="20"></clr-icon>
                </a>
              </td>
              <td>{{item['filename']}}</td>
              <td>
                <a *ngIf="(item['downloaded'] == false)" (click)="downloadFile(detail,item)"
                   class="btn btn-primary btn-sm">
                  <clr-icon shape="times" size="10"></clr-icon>
                  Download</a>
                <a *ngIf="(item['downloaded'] == true)" href="{{item['url']}}"
                   class="btn btn-success btn-sm">
                  <clr-icon shape="check" size="10"></clr-icon>
                  Erneuter Download</a>
              </td>
            </tr>
            </tbody>
          </table>
        </clr-dg-detail-header>


      </clr-dg-detail>

    </clr-datagrid>


  </div>
</div>

<div *ngIf="detailData != null">
  <clr-modal [(clrModalOpen)]="commentModal">
    <h3 class="modal-title">{{detailData.dentist}}</h3>
    <div class="modal-body">
      <p>Labor Anmerkung:</p>
      <form clrForm>
        <textarea clrTextarea [(ngModel)]="labNote" name="labNote" required></textarea>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline" (click)="commentModal = false">Cancel</button>
      <button type="button" class="btn btn-primary" (click)="writeLabComment()">Senden</button>
    </div>
  </clr-modal>
</div>

