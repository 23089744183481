<div class="clr-row">
  <div class="clr-col-1"></div>
  <div class="clr-col-10">
    <button (click)="deleteButton = !deleteButton" class="btn btn-danger btn-sm"><clr-icon shape="trash"></clr-icon> Löschen aktivieren</button>
    <button (click)="backbutton = !backbutton" class="btn btn-sm"><clr-icon shape="undo"></clr-icon>Zurück aktivieren</button>
    <button class="btn btn-sm"><clr-icon shape="moon"></clr-icon>Nachtjobs ausblenden</button>
    <div class="clr-row">
      <div class="clr-col-lg-2 clr-col-md-4 clr-col-sm-6 clr-col-12">
        <div class="card">
          <div class="card-header">
            <strong>Aufträge {{ unitCounts.orders }}</strong>
          </div>
          <div class="card-block">
            <div class="card-text">
              <h5>
                Einheiten: <strong>{{ unitCounts.total }}</strong>
              </h5>
              <h5>
                Schienen: <strong>{{ unitCounts.splint }}</strong>
              </h5>
              <h5>
                Preface: <strong>{{ unitCounts.preFace }}</strong>
              </h5>
              <hr />
              <h5>
                NEM: <strong>{{ unitCounts.nemReduce }}</strong>
              </h5>
              <h5>
                Zirkon: <strong>{{ unitCounts.zircon }}</strong>
              </h5>
              <h5>
                Wachs: <strong>{{ unitCounts.wax }}</strong>
              </h5>
              <h5>
                PMMA: <strong>{{ unitCounts.pmmaReduce }}</strong>
              </h5>
            </div>
          </div>
        </div>
      </div>
      <div class="clr-col-lg-2 clr-col-md-4 clr-col-sm-6 clr-col-12" *ngFor="let item of uploads">
        <div class="card">
          <div class="card-header">
            <div *ngIf="item.millagain" class="alert alert-danger alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                  <div class="alert-text">Arbeit muss wiederholt gefräst werden!</div>
                </div>
              </div>
              <br />
            </div>
            <div *ngIf="isToday(item.deliverydate)" class="alert alert-warning alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">Liefertermin ist HEUTE!</div>
                </div>
              </div>
              <br />
            </div>
            <div *ngIf="item.notes" class="alert alert-warning alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">
                    <span> {{ item.notes }}</span>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <strong>{{ item.companyname }}</strong>
          </div>
          <div class="card-block">
            <div class="card-title">
              {{ item.description }} <span class="badge badge-orange">{{ item.ordernumber }}</span>
              <clr-icon *ngIf="!item.nightshift" (click)="setNightShift(item)" shape="sun"></clr-icon>
              <clr-icon *ngIf="item.nightshift == true" (click)="disableNightshift(item)" class="is-solid" shape="moon"></clr-icon>
            </div>
            <div (click)="openChangeModal(item)" style="height: 5px" [ngStyle]="{ 'background-color': getColor(item.materialbrand) }"></div>
            <hr />
            <div class="card-text">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      Nachname<br />
                      <strong>{{ item.patlastname }}</strong>
                    </td>
                    <td>
                      Vorname<br />
                      <strong>{{ item.patname }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Werkstoff<br />
                      <strong>{{ item.materialbrand }}</strong>
                    </td>

                    <td>
                      Farbe<br />
                      <strong>{{ item.teethcolor }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Techniker<br />
                      <strong>{{ item.technican }}</strong>
                    </td>

                    <td>
                      Lieferdatum<br />
                      <strong>{{ item.deliverydate }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="openDetailpage(item)" class="btn btn-primary btn-block">Fräsen vorbereiten</button>
            <button (click)="moveBack(item)" [hidden]="!backbutton" class="btn btn-sm"><clr-icon shape="undo"></clr-icon>Zurück</button>
            <button (click)="deleteUploads(item)" [hidden]="!deleteButton" class="btn btn-danger btn-sm"><clr-icon shape="trash"></clr-icon>Löschen</button>
          </div>
        </div>
      </div>
      <div class="clr-col-lg-2 clr-col-md-6 clr-col-12" *ngFor="let item of uploads2">
        <div class="card">
          <div class="card-header">
            <div *ngIf="item.millagain" class="alert alert-danger alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-circle"></clr-icon>
                  </div>
                  <div class="alert-text">Arbeit muss wiederholt gefräst werden!</div>
                </div>
              </div>
              <br />
            </div>
            <div *ngIf="isToday(item.deliverydate)" class="alert alert-warning alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">Liefertermin ist HEUTE!</div>
                </div>
              </div>
              <br />
            </div>
            <div *ngIf="item.notes" class="alert alert-warning alert-sm" role="alert">
              <div class="alert-items">
                <div class="alert-item static">
                  <div class="alert-icon-wrapper">
                    <clr-icon class="alert-icon" shape="exclamation-triangle"></clr-icon>
                  </div>
                  <div class="alert-text">
                    <span> {{ item.notes }}</span>
                  </div>
                </div>
              </div>
              <br />
            </div>
            <strong>{{ item.companyname }}<span class="label label-purple">Teil 2</span></strong>
          </div>
          <div class="card-block">
            <div class="card-title">
              {{ item.description }} <span class="badge badge-orange">{{ item.ordernumber }}</span>
            </div>
            <div style="height: 5px" [ngStyle]="{ 'background-color': getColor(item.materialbrand) }"></div>
            <hr />
            <div class="card-text">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      Nachname<br />
                      <strong>{{ item.patlastname }}</strong>
                    </td>
                    <td>
                      Vorname<br />
                      <strong>{{ item.patname }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Werkstoff<br />
                      <strong>{{ item.materialbrand }}</strong>
                    </td>

                    <td>
                      Farbe<br />
                      <strong>{{ item.teethcolor }}</strong>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      Techniker<br />
                      <strong>{{ item.technican }}</strong>
                    </td>

                    <td>
                      Lieferdatum<br />
                      <strong>{{ item.deliverydate }}</strong>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="card-footer">
            <button (click)="openDetailpage2(item)" class="btn btn-primary btn-block">Teil 2 Für Fräsen vorbereiten</button>
            <button (click)="moveBack(item)" [hidden]="!backbutton" class="btn btn-sm"><clr-icon shape="undo"></clr-icon>Zurück</button>
            <button (click)="deleteUploads(item)" [hidden]="!deleteButton" class="btn btn-danger btn-sm"><clr-icon shape="trash"></clr-icon>Löschen</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="detailpage">
  <clr-modal [(clrModalOpen)]="detailPageModal">
    <h3 class="modal-title">
      Vorbereitung Fräsen <strong>{{ detailpage.description }}</strong>
    </h3>
    <div class="modal-body">
      <a (click)="toggleDisplay()" class="label label-purple clickable">2 Rohlinge</a>
      <clr-select-container>
        <label>Rohling</label>
        <select clrSelect [(ngModel)]="workingblank" (ngModelChange)="setFirstBlankCharge()" name="options">
          <option *ngFor="let order of dentalrohling" value="{{ order.blankName }}">
            {{ order.blankName }}
          </option>
        </select>
      </clr-select-container>

      <br />
      <strong>Charge: {{ workingcharge }}</strong>
      <div class="clr-form-control">
        <label  class="clr-control-label">Maschine</label>
        <div class="btn-group">
          <div class="radio btn">
            <input value="DT" [(ngModel)]="workingmachine" type="radio" name="btn-group-demo-radios" id="btn-demo-radio-1" />
            <label for="btn-demo-radio-1">DT</label>
          </div>
          <div class="radio btn">
            <input value="DCT" [(ngModel)]="workingmachine" type="radio" name="btn-group-demo-radios" id="btn-demo-radio-2" />
            <label for="btn-demo-radio-2">DCT</label>
          </div>
          <div class="radio btn">
            <input value="Organical" [(ngModel)]="workingmachine" type="radio" name="btn-group-demo-radios" id="btn-demo-radio-3" />
            <label for="btn-demo-radio-3">Organical</label>
          </div>
        </div>
      </div>
      <clr-input-container>
        <label>Zeit</label>
        <input [(ngModel)]="workingtime" type="time" clrInput />
      </clr-input-container>
      <div [hidden]="showSecondBlankLater">
        <clr-toggle-container clrInline>
          <clr-toggle-wrapper>
            <input type="checkbox" clrToggle name="options" [(ngModel)]="laterwork" />
          </clr-toggle-wrapper>
          <clr-control-helper>2te Rohling später</clr-control-helper>
        </clr-toggle-container>
      </div>
      <div [hidden]="showSecondBlank">
        <hr />
        <clr-select-container>
          <label>2 Rohling</label>
          <select clrSelect [(ngModel)]="workingblank2" (ngModelChange)="setSecondBlankCharge()" name="options">
            <option *ngFor="let order of dentalrohling" value="{{ order.blankName }}">
              {{ order.blankName }}
            </option>
          </select>
        </clr-select-container>
        <br />
        <strong>Charge: {{ workingcharge2 }}</strong>
        <div class="clr-form-control">
          <label for="basic" class="clr-control-label">Maschine</label>
          <div class="btn-group">
            <div class="radio btn">
              <input value="DT" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-1" />
              <label for="radio-1">DT</label>
            </div>
            <div class="radio btn">
              <input value="DCT" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-2" />
              <label for="radio-2">DCT</label>
            </div>
            <div class="radio btn">
              <input value="Organical" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-3" />
              <label for="radio-3">Organical</label>
            </div>
            <div class="radio btn">
              <input value="Keine" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-4" />
              <label for="radio-4">Keine</label>
            </div>
          </div>
        </div>
        <clr-input-container>
          <label>2 Zeit</label>
          <input [(ngModel)]="workingtime2" type="time" clrInput />
        </clr-input-container>
        <div>
          <clr-range-container>
            <label>{{ range }} von 12 Einheiten im 2ten Blank</label>
            <input [(ngModel)]="range" type="range" clrRange min="1" max="12" />
          </clr-range-container>
        </div>
      </div>
      <br />
      <hr />
      <br />
      <span><strong>Angaben für die Rechnungserstellung</strong></span>
      <clr-toggle-container clrInline>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle name="options" [(ngModel)]="workingbill" />
          <label>Rechnung</label>
        </clr-toggle-wrapper>
        <clr-toggle-wrapper>
          <input type="checkbox" clrToggle name="options" [(ngModel)]="workingshippingcost" />
          <label>Versand</label>
        </clr-toggle-wrapper>
      </clr-toggle-container>
    </div>
    <div [hidden]="submitbutton" class="modal-footer">
      <button (click)="detailPageModal = !detailPageModal" type="button" class="btn btn-outline">Abbrechen</button>
      <button (click)="Submit()" type="button" class="btn btn-primary">Freigeben</button>
    </div>
    <div [hidden]="!submitbutton" class="modal-footer">
      <button (click)="detailPageModal = !detailPageModal" class="btn btn-outline">Abbrechen</button>
      <button (click)="Submit2()" type="button" class="btn btn-primary">2 Rohlinge Freigeben</button>
    </div>
  </clr-modal>
</div>

<div *ngIf="detailpage">
  <clr-modal [(clrModalOpen)]="detailPageModal2">
    <h3 class="modal-title">
      Vorbereitung Fräsen <strong>{{ detailpage.description }}</strong>
    </h3>
    <div class="modal-body">
      <clr-select-container>
        <label>2 Rohling</label>

        <select clrSelect [(ngModel)]="workingblank2" (ngModelChange)="setSecondBlankCharge()" name="options">
          <option *ngFor="let blank of dentalrohling" value="{{blank.blankName}}">
            {{ blank.blankName }}
          </option>
        </select>
      </clr-select-container>
      <br />
<!--      <pre>{{workingtime2}}</pre>
      <pre>{{workingmachine2}}</pre>
      <pre>{{workingcharge2}}</pre>
      <pre>{{workingblank2}}</pre>-->
      <strong>Charge: {{ workingcharge2 }}</strong>
      <div class="clr-form-control">
        <label class="clr-control-label">Maschine</label>
        <div class="btn-group">
          <div class="radio btn">
            <input value="DT" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-1" />
            <label for="radio-1">DT</label>
          </div>
          <div class="radio btn">
            <input value="DCT" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-2" />
            <label for="radio-2">DCT</label>
          </div>
          <div class="radio btn">
            <input value="Organical" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-3" />
            <label for="radio-3">Organical</label>
          </div>
          <div class="radio btn">
            <input value="Keine" [(ngModel)]="workingmachine2" type="radio" name="radios" id="radio-4" />
            <label for="radio-4">Keine</label>
          </div>
        </div>
      </div>
      <clr-input-container>
        <label>2 Zeit</label>
        <input [(ngModel)]="workingtime2" type="time" clrInput />
      </clr-input-container>
    </div>


    <div class="modal-footer">
      <button (click)="detailPageModal2 = !detailPageModal2" type="button" class="btn btn-outline">Abbrechen</button>
      <button type="button" class="btn btn-primary" (click)="SubmitSingleSecondBlank()">Freigeben</button>
    </div>
  </clr-modal>
</div>

<div *ngIf="changemodaldata">
  <clr-modal [(clrModalOpen)]="changemodal">
    <h3 class="modal-title">
      Daten ändern <strong>{{ changemodaldata!.description }}</strong>
    </h3>
    <div class="modal-body">
      <form clrForm>
        <span class="clr-sr-only">Tabbing through form inputs will trigger validation messages to be read aloud.</span>
        <clr-input-container>
          <label>Vorname</label>
          <input clrInput type="text" [(ngModel)]="vorname" name="Vorname" />
        </clr-input-container>
        <clr-input-container>
          <label>Nachname</label>
          <input clrInput type="text" [(ngModel)]="nachname" name="Lastname" />
        </clr-input-container>
        <clr-input-container>
          <label>Beschreibung</label>
          <input clrInput type="text" [(ngModel)]="beschreibung" name="beschreibung" />
        </clr-input-container>
        <clr-input-container>
          <label>Techniker</label>
          <input clrInput type="text" [(ngModel)]="techniker" name="techniker" />
        </clr-input-container>
        <clr-input-container>
          <label>Werkstoff</label>
          <input clrInput type="text" [(ngModel)]="werkstoff" name="werkstoff" />
        </clr-input-container>
        <clr-input-container>
          <label>Werkstoffmarke</label>
          <input clrInput type="text" [(ngModel)]="marke" name="marke" />
        </clr-input-container>
        <clr-input-container>
          <label>Farbe</label>
          <input clrInput type="text" [(ngModel)]="farbe" name="farbe" />
        </clr-input-container>
      </form>
    </div>
    <div class="modal-footer">
      <button (click)="changemodal = !changemodal" type="button" class="btn btn-outline">Abbrechen</button>
      <button type="button" class="btn btn-primary" (click)="SubmitChange()">Ändern</button>
    </div>
  </clr-modal>
</div>
