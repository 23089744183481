<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
        <h1>Eltons Barcode Generator:</h1>
        <clr-input-container>
                <label>Barcode</label>
                <input clrInput type="text"  [(ngModel)]="barcode" name="Vorname"/>
            </clr-input-container>
        <div class="clr-row">
            <div  class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                <div class="card">
                    <div class="card-header">
                        <strong>{{datum | date}}</strong>
                    </div>
                    <div class="card-block">
                        
                    
                    <div class="card-title">
                            Barcodes
                    </div>
                    
                    <div class="card-text">
                            <ngx-barcode  [bc-font-size]="5"  [bc-width]="1" [bc-height]="50" [bc-value]="barcode" [bc-display-value]="true"></ngx-barcode>
                     
                    </div>

                    <button class="btn btn-primary btn-block" [useExistingCss]="true" printSectionId="print-section" ngxPrint>
                        Drucken
                    </button>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div style="height: 100;" id="print-section" [hidden]="true">
    <h5>Eltons Barcodes</h5><br>
        <ngx-barcode [bc-width]="0.7" [bc-height]="15" [bc-font-size]="5" [bc-value]="barcode"  [bc-display-value]="true"></ngx-barcode>
</div>