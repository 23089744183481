import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-testdaniel',
  templateUrl: './testdaniel.component.html',
  styleUrls: ['./testdaniel.component.scss']
})
export class TestdanielComponent implements OnInit {

  constructor(
    private http: HttpClient
  ) { }

  url: string = 'https://pacific-savannah-04788.herokuapp.com/';
  data;
  TotalMB: number;
  TotalCost: number;

  ngOnInit() {
  console.log('Works!'); 
  this.getData();
  } 
 
  getData() {
    this.http.get(this.url).subscribe(res => {
      this.data = res;
      console.log(this.data);
    });
    this.TotalCost = this.data.reduce((acc, cost) => acc + cost.cost, 0);
    console.log(this.TotalCost);

}
}
