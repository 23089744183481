<h3>Rohlinge:</h3>
<div>
  <button (click)="getMaterialtypeRohling(order)" *ngFor="let order of dentalmaterial" class="btn btn-primary">{{order.payload.doc.data().name}}</button>
  <button (click)="getallactivRohlinge()" class="btn btn-outline">Alle</button>
  <button (click)="getallinactivRohlinge()" class="btn btn-outline">Archiv</button>
</div>
<div *ngIf="allopen">
<clr-datagrid>
    <clr-dg-column>Bezeichnung</clr-dg-column>
    <clr-dg-column>Chargennummer</clr-dg-column>
    <clr-dg-column>Material</clr-dg-column>
    <clr-dg-column>Blankstatus</clr-dg-column>
    <clr-dg-column></clr-dg-column>

    <clr-dg-row *ngFor="let order of rohlinge">
      <clr-dg-cell>{{order.payload.doc.data().blankName}}</clr-dg-cell>
      <clr-dg-cell>{{order.payload.doc.data().cargeNumber}}</clr-dg-cell>
      <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().blankmaterial}}</span></clr-dg-cell>
      <clr-dg-cell>
        <span *ngIf="order.payload.doc.data().blankStatus" class="label label-success">Aktiv</span>
        <span *ngIf="!order.payload.doc.data().blankStatus" class="label label-danger">Abgefräst</span>
      </clr-dg-cell>
      <clr-dg-cell>
          <a *ngIf="order.payload.doc.data().blankStatus" (click)="Onsubmit(order)" class="label label-light-blue clickable">Rohling ist abgefräst</a>
          <a *ngIf="!order.payload.doc.data().blankStatus" (click)="DeleteBlank(order)" class="label label-light-blue clickable">Rohling komplett löschen</a>
      </clr-dg-cell>
    </clr-dg-row>
</clr-datagrid>
</div>
<div *ngIf="materialopen">
  <clr-datagrid>
      <clr-dg-column>Bezeichnung</clr-dg-column>
      <clr-dg-column>Chargennummer</clr-dg-column>
      <clr-dg-column>Material</clr-dg-column>
      <clr-dg-column>Blankstatus</clr-dg-column>
      <clr-dg-column></clr-dg-column>
  
      <clr-dg-row *ngFor="let order of specialrohling">
        <clr-dg-cell>{{order.payload.doc.data().blankName}}</clr-dg-cell>
        <clr-dg-cell>{{order.payload.doc.data().cargeNumber}}</clr-dg-cell>
        <clr-dg-cell><span class="label label-light-blue">{{order.payload.doc.data().blankmaterial}}</span></clr-dg-cell>
        <clr-dg-cell>
          <span *ngIf="order.payload.doc.data().blankStatus" class="label label-success">Aktiv</span>
          <span *ngIf="!order.payload.doc.data().blankStatus" class="label label-danger">Abgefräst</span>
        </clr-dg-cell>
        <clr-dg-cell>
            <a (click)="Onsubmit(order)" class="label label-light-blue clickable">
                Rohling ist abgefräst
            </a>
        </clr-dg-cell>
      </clr-dg-row>
  </clr-datagrid>
  </div>

