import {Component, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {IosRestService} from './ios-rest.service';
import {IosScansFireBaseResponse} from '../../types/ios-scans-firebase-responce.interface';
import {UncompletedUpload} from '../../types/uncompleted-upload';

@Component({
  selector: 'app-iosscans',
  templateUrl: './iosscans.component.html',
  styleUrls: ['./iosscans.component.scss']
})
export class IosscansComponent implements OnInit {
  public get OrderStatus(): typeof OrderStatus {
    return OrderStatus;
  }

  userUploads: IosScansFireBaseResponse[] = [];
  uncompletedUploadList: UncompletedUpload[] = [];
  uncompletedUploads = [];
  userUploadsFirstTwoWeeks = [];
  userUploadsTwoWeeks = [];
  userUploadsFourWeeks = [];
  userUploadsArchive = [];
  selected;
  detailData;
  detailItemData;
  commentModal = false;
  labNote;
  detailState;

  constructor(
    private db: AngularFirestore,
    private iosRest: IosRestService,
  ) {
  }

  ngOnInit() {
    this.db.collection<IosScansFireBaseResponse>('/iosScans', ref => {
      return ref.where('status', '<=', 2).orderBy('status').orderBy('onCreate', 'desc');
    }).snapshotChanges()
      .subscribe(snapshots => {
        this.userUploads = snapshots.map(e => {
          return e.payload.doc.data();

        });

        this.uncompletedUploadList = this.userUploads.map((v) => {
          return {
            dentist: v.dentist,
            uploads: v.uploads.filter((x) => !x.downloaded).map((y) => {
              return {
                patient: v.patientName,
                patientLastName: v.patientLastname,
                downloaded: y.downloaded,
                type: y.uploadType,
                date: new Date(+y.onCreate),
                fileName: y.filename,
                url: y.url,
                patientId: v.patientID
              };
            })
          };
        }).filter((z) => z.uploads.length > 0);

        this.userUploads = this.userUploads.sort((n1, n2) => {
          if (n1.age > n2.age) {
            return 1;
          }

          if (n1.onCreate > n2.onCreate) {
            return -1;
          }

          return 0;
        });

        const dateNow = Date.now();
        const twoWeeks = dateNow - 1209600000;
        const fourWeeks = dateNow - 2419200000;
        this.uncompletedUploads = [];
        const array = this.userUploads.filter(upload => upload.status === OrderStatus.PENDING);
        const mapArray = array.map(v => v.dentist);
        const uniqueArray = mapArray.filter((item, index) => mapArray.indexOf(item) === index);
        uniqueArray.forEach(value => {
          const result = array.filter(item => item.dentist === value);
          this.uncompletedUploads.push(result);
        });

        this.userUploadsFirstTwoWeeks = this.userUploads.filter(upload => (dateNow > +upload.onCreate && twoWeeks < +upload.onCreate));


        this.userUploadsTwoWeeks = this.userUploads.filter(upload => (twoWeeks > +upload.onCreate && fourWeeks < +upload.onCreate));
        this.userUploadsFourWeeks = this.userUploads.filter(upload => fourWeeks > +upload.onCreate);
      });


    this.db.collection('/iosScans', ref => {
      return ref.where('status', '==', 3);
    }).snapshotChanges()
      .subscribe(snapshots => {
        this.userUploadsArchive = snapshots.map(e => {
          return e.payload.doc.data();
        });
      });
  }


  daysSinceCreated(createDate): number {
    const dateNow = Date.now();
    const timeDiff = dateNow - createDate;
    const days = timeDiff / 86400000;
    return Math.round(days);
  }

  getStatusColorClass(number: number): string {
    if (number === 0) {
      return 'label label-success';
    }

    if (number > 0 && number < 2) {
      return 'label label-warning';
    }

    if (number > 2) {
      return 'label label-danger';
    }
  }

  checkNumber(): number {
    if (this.userUploadsFirstTwoWeeks != null) {
      return this.userUploadsFirstTwoWeeks.length;
    }
    return 0;
  }


}

export interface UncompletedDentist {
  dentist: string;
  uploads: object[];
}

export interface UncompletedUploads {
  patientName: string;
  patientLastname: string;
  daysSinceStatus: number;
}


enum OrderStatus {
  PENDING,
  NEW,
  COMPLETE,
  ARCHIVE,
}
