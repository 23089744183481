<div class="clr-row">
    <div class="clr-col-1">
    </div>
    <div class="clr-col-10">
      <h1>SIMs:</h1>
      <h3>Sim kosten</h3>
      <div *ngIf="data">
      <clr-datagrid> 
        
        <clr-dg-column [clrDgField]="'name'">Name</clr-dg-column>
        <clr-dg-column [clrDgField]="'tag'">Besch7ürueibung</clr-dg-column>
        <clr-dg-column [clrDgField]="'msisdn'">SIM Nummer</clr-dg-column>
        <clr-dg-column [clrDgField]="'totalTrafficMB'">Total MB</clr-dg-column> 
        <clr-dg-column [clrDgField]="'totalbytes'">Byte</clr-dg-column> 
        <clr-dg-column [clrDgField]="'cost'">Kosten</clr-dg-column>´
        <clr-dg-column [clrDgField]="'status'">Status</clr-dg-column>

    
        <clr-dg-row *clrDgItems="let item of data">

            <clr-dg-cell>{{ item.name }}</clr-dg-cell>
            <clr-dg-cell>{{ item.tag }}</clr-dg-cell>
            <clr-dg-cell>{{ item.msisdn }}</clr-dg-cell>
            <clr-dg-cell>{{ item.totalTrafficMB }} MB</clr-dg-cell> 
            <clr-dg-cell>{{ item.totalbytes }}</clr-dg-cell> 
            <clr-dg-cell>{{ item.cost }} €</clr-dg-cell>
            <clr-dg-cell>
                    <span>Aktiv</span>
            </clr-dg-cell>
        </clr-dg-row>
    </clr-datagrid>
</div>
</div>
</div>
