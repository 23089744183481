<div class="clr-row">
  <div class="clr-col-sm-12 clr-col-md-6">
    <h1>DT Maschine</h1>
  </div>
  <div *ngFor="let order of millingtime" class="clr-col-sm-12 clr-col-md-6">
    <div *ngIf="order.payload.doc.data().time > dateNow">
      <h1><countdown [config]="{ stopTime: order.payload.doc.data().time }"></countdown></h1>
    </div>
  </div>
</div>
<div *ngFor="let order of millingtime">
  <div class="clr-row">
    <div class="clr-col-sm-12 clr-col-md-6">
      <form clrForm [formGroup]="this.firebaseService.mb1timerform">
        <clr-input-container>
          <label>Zeit</label>
          <input formControlName="millingtime" type="time" class="formControl" clrInput />
        </clr-input-container>
      </form>
      <button (click)="setTime(data)" class="btn btn-sm">Start</button>
    </div>
    <div *ngIf="order.payload.doc.data().time > dateNow" class="clr-col-sm-12 clr-col-md-6">
      <circle-progress
        [percent]="((dateNow - order.payload.doc.data().starttime) / order.payload.doc.data().timetotal) * 100"
        [maxPercent]="100"
        [radius]="50"
        [outerStrokeColor]="'#78C000'"
        [animation]="true"
        [animationDuration]="300"
        [startFromZero]="false"
      ></circle-progress>
    </div>
  </div>
</div>
<div class="clr-row">
  <div class="clr-col-sm-12 clr-col-md-5">
    <h3>Halter Links:</h3>
  </div>
  <div class="clr-col-sm-12 clr-col-md-7">
    <div *ngFor="let order of HolderLeftBlank">
      <h3>
        <strong>{{ order.payload.doc.data().workingblank }}</strong>
      </h3>
    </div>
    <div *ngFor="let order of HolderLeftBlank2">
      <h3>
        <strong>{{ order.payload.doc.data().workingblank2 }}</strong>
      </h3>
    </div>
  </div>
</div>
<table class="table">
  <thead>
    <tr>
      <th>Patient</th>
      <th>Beschreibung</th>
      <th>Techniker</th>
      <th>Zeit</th>
      <th>OK</th>
      <th>Zurück</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of HolderLeft">
      <td>{{ order.payload.doc.data().patlastname }}, {{ order.payload.doc.data().patname }}</td>
      <td>{{ order.payload.doc.data().description }}</td>
      <td>{{ order.payload.doc.data().technican }}</td>
      <td>{{ order.payload.doc.data().workingtime }}</td>
      <td><clr-icon (click)="Submit(order)" shape="success-standard"></clr-icon></td>
      <td><clr-icon (click)="moveBack(order)" shape="times-circle" class="is-solid"></clr-icon></td>
    </tr>
    <tr *ngFor="let order of HolderLeft2">
      <td>{{ order.payload.doc.data().patlastname }}, {{ order.payload.doc.data().patname }}</td>
      <td>{{ order.payload.doc.data().description }}</td>
      <td>{{ order.payload.doc.data().technican }}</td>
      <td>{{ order.payload.doc.data().workingtime2 }}</td>
      <td><clr-icon (click)="Submit2(order)" shape="success-standard"></clr-icon></td>
      <td><clr-icon (click)="moveBack2(order)" shape="times-circle" class="is-solid"></clr-icon></td>
    </tr>
  </tbody>
</table>
<br />
<div class="clr-row">
  <div class="clr-col-sm-12 clr-col-md-5">
    <h3>Halter Rechts:</h3>
  </div>
  <div class="clr-col-sm-12 clr-col-md-7">
    <div *ngFor="let order of HolderRightBlank">
      <h3>
        <strong>{{ order.payload.doc.data().workingblank }}</strong>
      </h3>
    </div>
    <div *ngFor="let order of HolderRightBlank2">
      <h3>
        <strong>{{ order.payload.doc.data().workingblank2 }}</strong>
      </h3>
    </div>
  </div>
</div>
<table class="table">
  <thead>
    <tr>
      <th>Patient</th>
      <th>Beschreibung</th>
      <th>Techniker</th>
      <th>Zeit</th>
      <th>OK</th>
      <th>Zurück</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of HolderRight">
      <td>{{ order.payload.doc.data().patlastname }}, {{ order.payload.doc.data().patname }}</td>
      <td>{{ order.payload.doc.data().description }}</td>
      <td>{{ order.payload.doc.data().technican }}</td>
      <td>{{ order.payload.doc.data().workingtime }}</td>
      <td><clr-icon (click)="Submit(order)" shape="success-standard"></clr-icon></td>
      <td><clr-icon (click)="moveBack(order)" shape="times-circle" class="is-solid"></clr-icon></td>
    </tr>
    <tr *ngFor="let order of HolderRight2">
      <td>{{ order.payload.doc.data().patlastname }}, {{ order.payload.doc.data().patname }}</td>
      <td>{{ order.payload.doc.data().description }}</td>
      <td>{{ order.payload.doc.data().technican }}</td>
      <td>{{ order.payload.doc.data().workingtime2 }}</td>
      <td><clr-icon (click)="Submit2(order)" shape="success-standard"></clr-icon></td>
      <td><clr-icon (click)="moveBack2(order)" shape="times-circle" class="is-solid"></clr-icon></td>
    </tr>
  </tbody>
</table>
