<div class="clr-row">
        <div class="clr-col-1">
        </div>
        <div class="clr-col-10">
          <h1>Zum Fräsen: <strong>{{size}} </strong></h1>
          <a (click)="toggleDisplay()" class="btn btn-sm"><clr-icon  shape="download"></clr-icon>Mehr Infos</a>
          <a (click)="back = !back" class="btn btn-sm"><clr-icon  shape="wand"></clr-icon>Rückgängig aktivieren</a>
          <div class="clr-row">
              <div *ngFor="let order of files" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                  <div class="card">
                      <div class="card-header">
                          <strong>{{order.payload.doc.data().companyname}}</strong>
                      </div>
                      <div class="card-block">
                          <div class="card-title">
                                {{order.payload.doc.data().description}} <span class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                          </div>
                          <hr>
                          <div class="card-text">
                                <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>Rohling
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().workingblank }}</strong>
                                                </td>
                                                <td>Maschine
                                                <br>
                                                <strong>{{ order.payload.doc.data().workingmachine }}</strong>
                                            </tr>
                                            <tr>
                                                    <td>Werkstoff
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                                                    <td>Farbe
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                                            </tr>
                                            <tr>
                                                    <td>Zeit
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().workingtime }}</strong>
                                                    <td>Lieferdatum
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                                            </tr>
                                        </tbody>
                                    </table>
                            <div [hidden]="showInfo">
                            <strong>Auftragsdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                            <br>
                            <strong>Was wurde gesendet?</strong>
                            <br>
                            <span>{{ order.payload.doc.data().whattosend }}</span>
                            <br>
                            <hr>
                            <strong>Modelle drucken?</strong>
                            <br>
                            <span>{{ order.payload.doc.data().modelprinting }}</span>
                            <br>
                            <hr>
                            <strong>Dateiname:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().path }}</span>
                            <br>
                            <hr>
                            <clr-signpost>
                                    <button class="btn btn-link" 
                                            clrSignpostTrigger>
                                            Notitzen
                                                <clr-icon shape="details"></clr-icon>
                                    </button>
                                    <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                            {{ order.payload.doc.data().notes }}
                                    </clr-signpost-content>
                                </clr-signpost>
                          </div>
                      </div>
                    </div>
                    <div class="btn-group btn-primary">
                        <button (click)="Submit(order)" class="btn">Links</button>
                        <button (click)="SubmitRight(order)" class="btn">Rechts</button>
                        <button  [disabled]="!back" (click)="moveback2(order)" class="btn">
                                <clr-icon shape="eraser"></clr-icon>
                                <span class="clr-icon-title">Zurück</span>
                        </button>
                    </div>
                  </div>
              </div>
              <div *ngFor="let order of files2" class="clr-col-lg-3 clr-col-md-6 clr-col-12">
                  <div class="card">
                      <div class="card-header">
                          <strong>{{order.payload.doc.data().companyname}} <span class="label label-purple">Teil 2</span></strong>
                      </div>
                      <div class="card-block">
                          <div class="card-title">
                                {{order.payload.doc.data().description}} <span class="badge badge-orange">{{ order.payload.doc.data().ordernumber }}</span>
                          </div>
                          <hr>
                          <div class="card-text">
                                <table class="table">
                                        <tbody>
                                            <tr>
                                                <td>Rohling
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().workingblank2 }}</strong>
                                                </td>
                                                <td>Maschine
                                                <br>
                                                <strong>{{ order.payload.doc.data().workingmachine2 }}</strong>
                                            </tr>
                                            <tr>
                                                    <td>Werkstoff
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().materialbrand }}</strong>
                                                    <td>Farbe
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().teethcolor }}</strong>
                                            </tr>
                                            <tr>
                                                    <td>Zeit
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().workingtime2 }}</strong>
                                                    <td>Lieferdatum
                                                    <br>
                                                    <strong>{{ order.payload.doc.data().deliverydate }}</strong>
                                            </tr>
                                        </tbody>
                                    </table>
                            <div [hidden]="showInfo">
                            <strong>Auftragsdatum:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().date | date:'medium' }}</span>
                            <br>
                            <strong>Was wurde gesendet?</strong>
                            <br>
                            <span>{{ order.payload.doc.data().whattosend }}</span>
                            <br>
                            <hr>
                            <strong>Modelle drucken?</strong>
                            <br>
                            <span>{{ order.payload.doc.data().modelprinting }}</span>
                            <br>
                            <hr>
                            <strong>Dateiname:</strong>
                            <br>
                            <span>{{ order.payload.doc.data().path }}</span>
                            <br>
                            <hr>
                            <clr-signpost>
                                    <button class="btn btn-link" 
                                            clrSignpostTrigger>
                                            Notitzen
                                                <clr-icon shape="details"></clr-icon>
                                    </button>
                                    <clr-signpost-content [clrPosition]="'right-middle'" *clrIfOpen>
                                            {{ order.payload.doc.data().notes }}
                                    </clr-signpost-content>
                                </clr-signpost>
                          </div>
                      </div>
                    </div>
                    <div class="btn-group btn-primary">
                        <button (click)="Submit2(order)" class="btn">Links</button>
                        <button (click)="Submit2Right(order)" class="btn">Rechts</button>
                        <button  [disabled]="!back" (click)="moveback3(order)" class="btn">
                                <clr-icon shape="eraser"></clr-icon>
                                <span class="clr-icon-title">Zurück</span>
                        </button>
                        </div>
                    </div>
                  </div>
              </div>
          </div>
        </div>