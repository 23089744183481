import { Component, OnInit } from '@angular/core';
import { FirebaseService } from '../shared/firebase.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { DatePipe } from '@angular/common'
import { ExcelService } from 'src/app/excel.service';

@Component({
  selector: 'app-statistics',
  templateUrl: './statistics.component.html',
  styleUrls: ['./statistics.component.scss']
})
export class StatisticsComponent implements OnInit {

  constructor(
    private firebaseService: FirebaseService,
    private afs: AngularFirestore,
    public datepipe: DatePipe,
    private excelService: ExcelService


    
  ) { }

  files;
  today;
  date;
  datePlus1;
  latest_date;
  negativdate2;
  test;
  exportfiles;
  firstdate: string = "22.10.2019";
  seconddate: string = "22.10.2019";
  unixfirstdate;
  unixseconddate;
  export: boolean = false;
  companyname;
  description;
  testarray;
  numberfiles;

  CompanyNames;
  
  pieChartLabels = ["Dental-Technik Hamm", "Keradent", "DTH Schmallenberg"];
  pieChartData = [100, 100, 100, 100];
  pieChartType = 'pie';

  showdatafront: boolean = false;

  view: any[] = [600, 400];

  testdata;
  testdata2;

  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = false;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = 'Firma';
  showYAxisLabel = true;
  yAxisLabel = 'Einheiten';
  timeline = true;

  colorScheme = {
    domain: ['#9370DB', '#87CEFA', '#FA8072', '#FF7F50', '#90EE90', '#9370DB']
  };

  public pilots = [
    {
      id: 10,
      name: "Poe Dameron",
      units: 74,
    },
    {
      id: 2,
      name: "Temmin 'Snap' Wexley",
      units: 45,
    },
    {
      id: 41,
      name: "Tallissan Lintra",
      units: 47,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 12,
    },
    {
      id: 10,
      name: "Poe Dameron",
      units: 14,
    },
    {
      id: 2,
      name: "Temmin 'Snap' Wexley",
      units: 30,
    },
    {
      id: 41,
      name: "Tallissan Lintra",
      units: 16,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    },
    {
      id: 99,
      name: "Ello Asty",
      units: 22,
    }
  ];

  //pie
  showLabels = true;

  // data goes here
public single = [
  {
    "name": "China",
    "value": 50
  },
  {
    "name": "USA",
    "value": 10
  },
  {
    "name": "Norway",
    "value": 10
  },
  {
    "name": "Japan",
    "value": 20
  },
  {
    "name": "Germany",
    "value": 30
  },
  {
    "name": "France",
    "value": 23
  }
];

public multi = [
  {
    "name": "China",
    "series": [
      {
        "name": "2018",
        "value": 2243772
      },
      {
        "name": "2017",
        "value": 1227770
      }
    ]
  },

  {
    "name": "USA",
    "series": [
      {
        "name": "2018",
        "value": 1126000
      },
      {
        "name": "2017",
        "value": 764666
      }
    ]
  },

  {
    "name": "Norway",
    "series": [
      {
        "name": "2018",
        "value": 296215
      },
      {
        "name": "2017",
        "value": 209122
      }
    ]
  },

  {
    "name": "Japan",
    "series": [
      {
        "name": "2018",
        "value": 257363
      },
      {
        "name": "2017",
        "value": 205350
      }
    ]
  },

  {
    "name": "Germany",
    "series": [
      {
        "name": "2018",
        "value": 196750
      },
      {
        "name": "2017",
        "value": 129246
      }
    ]
  },

  {
    "name": "France",
    "series": [
      {
        "name": "2018",
        "value": 204617
      },
      {
        "name": "2017",
        "value": 149797
      }
    ]
  }
];

  ngOnInit() {
    this.setdate();
    console.log(this.pilots);
    console.log(this.pilots);
    const totalYears = this.pilots.reduce((acc, pilot) => acc + pilot.units, 0);
    console.log(totalYears);
  }

  onlyUnique(value, index, self) { 
    return self.indexOf(value) === index;
}

onlyUniqueFilter() {
  let newarray = this.exportfiles
  this.testdata2 = [...new Set(newarray.map( x=> x.companyname))]
  .map(companyname => {
    return {
      name: companyname,
      value: this.exportfiles.filter( v => v.companyname === companyname).reduce((acc, units) => acc + units.ordernumber, 0),
    };
  });
  console.log(this.testdata2);
}

  exportAsXLSX(): void {
    this.excelService.exportAsExcelFile(this.exportfiles, 'Uebersicht');
 }

 showData() {
   this.showdatafront = true;
   this.getCompanyNames();
 }

 getCompanyNames() {
  this.CompanyNames = this.companyname.filter((v, i, a) => a.indexOf(v) === i); 
  console.log(this.CompanyNames.toString);
  console.log(this.CompanyNames);
 }

 convertUnixDates () {
   console.log(this.firstdate)
   console.log(this.seconddate)
   const firstdatesplit = this.firstdate.split('.');
   const newfirstdate = (firstdatesplit[2] + '/' + firstdatesplit[1] + '/' + firstdatesplit[0])
   const seconddatesplit = this.seconddate.split('.');
   const newseconddate = (seconddatesplit[2] + '/' + seconddatesplit[1] + '/' + seconddatesplit[0])
   const unixfirstdate = Math.round(new Date(newfirstdate).getTime()/1000)
   const unixseconddate = Math.round(new Date(newseconddate).getTime()/1000)
   this.unixfirstdate = unixfirstdate + '000';
   this.unixseconddate = unixseconddate + '000';
   console.log(this.unixfirstdate);
   console.log(this.unixseconddate);
   console.log(Date.now());
   this.getExportdata();
   this.export = true;
 }

 getExportdata() {
   this.afs.collection('files', ref => {
    return ref.where('date', '>=', this.unixfirstdate).where('date', '<=', this.unixseconddate);
  }).get()
   .subscribe(snaps => {
     this.exportfiles = (snaps.docs.map(snap => snap.data()))
     this.companyname = (snaps.docs.map(snap => snap.data().companyname))
     this.description = (snaps.docs.map(snap => snap.data().description))
     console.log(this.exportfiles);
     this.numberfiles = this.exportfiles.length;
   });
   
   this.onlyUniqueFilter()
 }

  setdate() {
    this.date = new Date();
    this.latest_date = this.datepipe.transform(this.date, 'dd.MM.yyyy');
    console.log(this.latest_date);
  }
  getUploads = () =>
  this.firebaseService.getallUploads().subscribe(res => (this.files = res))

  setnegativdatetest(data) {
    console.log(data.payload.doc.data().deliverydate)
  }

  setnegativdate(data) {
    const dmy = data.payload.doc.data().deliverydate;   // your input string
    const a = dmy.split('.'); // split it at the colons
    // minutes are worth 60 seconds. Hours are worth 60 minutes.
    const day = 100 -(+a[0]);
    const month = 100 - (+a[1]);
    const year = 10000 - (+a[2]);
    const d = day.toString()
    const m = month.toString()
    const y = year.toString()
    this.negativdate2 = ( y + m + d)
    console.log(day);
    console.log(month);
    console.log(year);
    console.log(this.negativdate2);
  }

  Submit(data) {
    this.setdatenegativ(data);
    }

  setdatenegativ = data =>
  this.firebaseService.setnegativdatefinal(data)
}

